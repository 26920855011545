import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import bg from "../../public/BGS/foot.png";
import gold from "../../assets/v2/gold.png";
import gold_pot from "../../assets/v2/gold_pot.png";
import TelegramIcon from "@material-ui/icons/Telegram";
import TwitterIcon from "@material-ui/icons/Twitter";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    background: "#181849",
    padding: "6vh 0 4vh",
    textAlign: "center",
    color: "white",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      background: "#030326",
      padding: "20px",
    },
    position: "relative",
  },
  txt1: {
    fontWeight: "500",
    fontSize: "21px",
    marginBottom: "1em",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
      fontSize: "1.1em",
    },
  },
  txt2: {
    fontWeight: "300",
    fontSize: "19.2px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
    },
  },
  social: {
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    marginTop: "2em",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "50px",
    },
  },
  social_icon: {
    color: "white",
  },
  imgLeft: {
    position: "absolute",
    left: "4em",
    bottom: "0",
    [theme.breakpoints.down("sm")]: {
      bottom: "-20px",
      transform: "scale(0.5)",
    },
  },
  imgRight: {
    right: "4em",
    bottom: "0",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      bottom: "-20px",
      transform: "scale(0.5)",
    },
  },
}));

export default function Footer_() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <img src={gold} className={classes.imgLeft}></img>
        <div className={classes.txt1}>
          Need a fundraiser? Why not create a Big Tent EVENT!
        </div>
        <div className={classes.txt2}>
          Partner with us at bigtent@crystalelephant.net!
        </div>
        <div className={classes.social}>
          <IconButton
            className={classes.social_icon}
            onClick={() => window.open("https://twitter.com/CEToken")}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            className={classes.social_icon}
            onClick={() => window.open("https://twitter.com/CEToken")}
          >
            <TelegramIcon />
          </IconButton>
          <IconButton
            className={classes.social_icon}
            href="mailto:bigtent@crystalelephant.net"
          >
            <MailOutlineIcon />
          </IconButton>
        </div>
        <img src={gold_pot} className={classes.imgRight}></img>
      </div>
    </>
  );
}
