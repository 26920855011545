var metacoinConfig = {
  mainnet: {
    contractAddress: "41c6ca18531fcd335b60421c8b995b59fafa58c1c6",
    contractAddressBase: "TU6JtrDsBSGeiwyC1J8Frt7euUZj95cx7v",
    secondaryAccount: "TW7ibcJbWSKDuqrkScaGf1KPRpQxn6C7J1",
    privateKey:
      "dcf681731f143736234292445e1bd7e4cb7f3c98d6381782e7567362a860b959",
    fullHost: "https://api.trongrid.io",
    url: "https://tronscan.org",
  },
  shasta: {
    contractAddress: "411fae1b28a28244da5eb795b5a33e67ea4548d635",
    contractAddressBase: "TCriX7GV5s6gkkYJkTnapBhQSRBhiqCqeD",
    secondaryAccount: "TW7ibcJbWSKDuqrkScaGf1KPRpQxn6C7J1",
    privateKey:
      "dcf681731f143736234292445e1bd7e4cb7f3c98d6381782e7567362a860b959",
    fullHost: "https://api.shasta.trongrid.io",
    url: "https://shasta.tronscan.org",
  },
  // private: {
  //   contractAddress: "41ca5cbc4fdc892c49dd5fee99183a43ee27a44627",
  //   contractAddressBase: "TURCbC1vewHtVzpsscEKr7yuikRHnrnnQq",

  //   secondaryAccount: "TTYWmz3CA4hZ4gVx3oZpnscPTanwoA2YXB",

  //   fullHost: "https://node.crystalelephant.net",
  //   url: "https://node.crystalelephant.net",
  // },
  private: {
    contractAddress: "41726c1e2a45b03f4ffbaccddc67f1b54f2351c182",
    contractAddressBase: "TLQDZ5xo5T7iK2MKuDKmnDyRXCcpxxvDde",

    secondaryAccount: "TW7ibcJbWSKDuqrkScaGf1KPRpQxn6C7J1",

    fullHost: "https://api.nileex.io",
    url: "https://nile.trongrid.io/",
  },
};

export default metacoinConfig;
