import React, { useEffect } from "react";
import { useState } from "react";
import {
  makeStyles,
  Dialog,
  ThemeProvider,
  createMuiTheme,
  CircularProgress,
  TextField,
  Snackbar,
} from "@material-ui/core";
import { VscChromeClose } from "react-icons/vsc";
import { HiMinus, HiPlus } from "react-icons/hi";
import { Button } from "@material-ui/core";
import Background from "../../assets/images/popupBg.png";
import metacoinConfigCeto from "../../metacoin-config-ceto";
import "./modal_.css";
import axios from "axios";
import metacoinConfig from "../../metacoin-config";
import ceto from "../../assets/v2/ceto_white_img.png";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#C90001",
    },
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    background: "#272750",
    // overflow: "auto",

    backgroundSize: "cover",
    borderRadius: "72px",
    justifySelf: "center",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
  content: {
    paddingTop: "18px",
    paddingRight: "70px",
    paddingLeft: "70px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "5px",
      paddingRight: "1em",
      paddingLeft: "1em",
    },
  },
  topbar: {
    display: "flex",
    flexDirection: "row",
    color: "white",
  },
  title: {
    textAlign: "left",
    flex: 9,
    color: "white",
    fontSize: "40px",
    lineHeight: "60px",
    fontWeight: "400",
    fontFamily: "Poppins",
  },
  icon: {
    flex: 1,
    fontSize: "30px",
  },

  iconCursor: {
    cursor: "pointer",
  },

  ticketCount: {
    width: "40px",
    display: "inline-block",
    textAlign: "center",
    marginRight: "10px",
    marginLeft: "10px",
    borderRadius: "6px",
    border: " 1px solid #FF5C34",
    background: "transparent",
    color: "#FF5C34",
  },

  table: {
    // marginRight: "45px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  row: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    marginBottom: "16px",
  },

  column1: {
    flex: 1,
    textAlign: "left",
  },
  column2: {
    textAlign: "right",
  },
  hr1: {
    width: "100%",
    backgroundColor: "#C7EDFA",
    display: "block",
    height: "0.3px",
    marginTop: "0px",
    marginDown: "5px",
    position: "relative",
  },
  buttons: {
    width: "100%",
    display: "flex",
    marginTop: "2em",
    marginBottom: "1em",

    justifyContent: "center",
  },
  mobileButtons: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },
  button: {
    margin: "10px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#FFFFFF",
    align: "center",
    textAlign: "center",
    borderRadius: "100px",
    width: "180px",
    height: "50px",
    background: "linear-gradient(270deg, #E35B30 -23.9%, #E33045 80.04%)",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500",
      fontSize: "16px",
      width: "100%",
    },
  },
  sec_button: {
    margin: "10px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "30px",
    align: "center",
    textAlign: "center",
    borderRadius: "100px",
    width: "180px",
    height: "50px",
    border: " 1px solid #FF5C34",
    backgroundColor: "transparent",
    color: "#FF5C34",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",

      backgroundColor: "transparent",
    },
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500",
      fontSize: "16px",
      width: "100%",
    },
  },
  tabs: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "1.5em",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "space-between",
      textAlign: "center",
    },
  },

  txt22: {
    fontSize: "16px",
    fontWeight: "200",
    color: "white",
    marginBottom: "10px",
  },
  orange: {
    fontSize: "18px",
    fontWeight: "300",
    color: "#FF7629",
  },
  input: {
    borderColor: "#FF5C34",
    border: "1px solid ",
    borderRadius: "3px",
    color: "#FFC1B9",
    backgroundColor: "transparent",
    outline: "none",
    padding: "1em",
    "&::placeholder": {
      color: "#FFC1B9",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        fontWeight: 300,
      },
    },
  },
  left_: {
    fontSize: "23px",
    fontWeight: "normal",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "19px",
      lineHeight: "28.5px",
    },
  },
  right_: {
    fontSize: "23px",
    fontWeight: "normal",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "19px",
      lineHeight: "28.5px",
    },
  },
  amount: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      color: "#FF5037",
    },
  },
  ceto: {
    width: "19.26px",
    height: "26px",
  },
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const RebateModal = ({
  open,
  setShowModal,
  buyTicket,
  approved,
  approve,
  allowance,
  processing,
  contractData,
  withdrawRebate,
  rebate,
  node,
}) => {
  const classes = useStyles();

  const [ticketCount, setTicketCount] = useState(1);
  const [ticketPrice, setTicketPrice] = useState();
  const [initialTrxPrice, setInitialTrxPrice] = useState();
  const [err, handleErr] = useState(false);

  const [transactionFee, setTransactionFee] = useState(0.5);
  const [tab, setTab] = useState(1);
  const [tronToWithdraw, setTronToWithdraw] = useState();
  const [rebate_, setRebate] = useState();
  const [positions, setPositions] = React.useState({
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal } = positions;
  const action = () => {
    var config = {
      method: "get",
      url: `https://api.crystalelephant.net/api/cache/?function=getTokenFee&arguments=${
        tronToWithdraw * 1e6
      }&force=${1}&network=${metacoinConfig[node].fullHost}&contract_address=${
        metacoinConfig[node].contractAddressBase
      }&sender_address=${metacoinConfig[node].secondaryAccount}`,
    };
    axios(config)
      .then(function (res) {
        var data = res.data.data;
        console.log(data);

        var config = {
          method: "get",
          url: `https://api.crystalelephant.net/api/cache/?function=tokensToTron_&arguments=${data}&force=${1}&network=${
            metacoinConfigCeto[node].fullHost
          }&contract_address=${
            metacoinConfigCeto[node].contractAddressBase
          }&sender_address=${metacoinConfigCeto[node].secondaryAccount}`,
        };
        axios(config)
          .then(function (res) {
            console.log(res, "rebate");
            withdrawRebate(tronToWithdraw);
          })
          .catch(function (error) {
            console.log(error);
            handleErr(true);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    var config = {
      method: "get",
      url: `https://api.crystalelephant.net/api/cache/?function=getRebateBalance&force=${1}&network=${
        metacoinConfig[node].fullHost
      }&contract_address=${
        metacoinConfig[node].contractAddressBase
      }&sender_address=${metacoinConfig[node].secondaryAccount}`,
    };
    axios(config)
      .then(function (res) {
        console.log(res, "rebate");
        setRebate(res.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [contractData]);

  return (
    <div className={`modal ${open ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className={classes.root}>
          <div className={classes.tabs}>
            <div className={classes.left_}>Claim Reward</div>
            <div className={classes.right_}>
              <span className={classes.amount}>
                {rebate && (rebate / 1e6).toFixed(3)}
              </span>{" "}
              CETO <img src={ceto} className={classes.ceto}></img>
            </div>
          </div>
          <div className={classes.content}>
            <div className={classes.table}>
              <div className={classes.txt22}>
                You have{" "}
                <span className={classes.amount}>
                  {rebate_ && rebate_ / 1e6}
                </span>{" "}
                TRX
              </div>
              <input
                id="outlined-basic"
                placeholder="Enter the TRX value"
                variant="outlined"
                className={classes.input}
                value={tronToWithdraw}
                onChange={e => setTronToWithdraw(e.target.value)}
              />
              {/* <hr className={classes.hr1} /> */}

              <div className={classes.row}>
                <div className={classes.buttons}>
                  <ThemeProvider theme={theme}>
                    <div className={classes.mobileButtons}>
                      <Button
                        className={classes.sec_button}
                        color="secondary"
                        variant="contained"
                        onClick={() => setShowModal(false)}
                      >
                        CANCEL
                      </Button>
                      <Button
                        className={classes.button}
                        color="secondary"
                        variant="contained"
                        onClick={() => action()}
                      >
                        {processing == 0 ? (
                          "Claim"
                        ) : (
                          <CircularProgress
                            style={{
                              color: "white",
                              width: "25px",
                              height: "25px",
                            }}
                          />
                        )}
                      </Button>
                    </div>
                  </ThemeProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={err}
        autoHideDuration={6000}
        onClose={() => handleErr(false)}
      >
        <Alert onClose={() => handleErr(false)} severity="error">
          Rebate value too small!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default RebateModal;
