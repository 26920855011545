import Hourglass from "./contracts/Hourglass.json";

import metacoinConfig from "./metacoin-config-ceto";

var contractAddress;
var tronWeb;
try {
  const TronWeb = require("tronweb");

  window.addEventListener("load", async event => {
    tronWeb = window.tronWeb;
    contract_obj.tronWebProvider = window.tronWeb;
    console.log("page is fully loaded");
    var node_ =
      window.tronWeb &&
      (window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
        ? "shasta"
        : window.tronWeb.fullNode.host == "https://api.trongrid.io"
        ? "mainnet"
        : "private");
    if (node_) {
      contractAddress = metacoinConfig[node_].contractAddress;
      contract_obj.init(node_);
    }
  });
  // document.write(window.tronWeb.defaultAddress.base58)
} catch (err) {
  console.log(err);
  // alert(`The app looks not configured. Please run "npm run migrate" ${err}`);
}

const contract_obj = {
  tronWebProvider: tronWeb,
  contracts: {},
  accounts: [],
  contractAddress: contractAddress,
  // privateKey:
  //   "CBB6FDC134599AD3FF5D8E4A23E87EB1289BC6985A3380D8FAA35CDBA677DA05",
  feeLimit: 100000000,
  callValue: 0,

  contract_instace: null,
  init: async function (node_) {
    if (node_) {
      this.contractAddress = metacoinConfig[node_].contractAddress;
      this.contract_instace =
        node_ && tronWeb && (await tronWeb.contract().at(this.contractAddress));
      // console.log(this.contract_instace, "contract_instace");
    }
    this.accounts = [
      this.tronWebProvider && this.tronWebProvider.defaultAddress.base58,
    ];
  },
  approveFunc: async function (ceto, recipient_address) {
    var data = await this.contract_instace
      .approve(recipient_address, ceto)
      .send({
        feeLimit: this.feeLimit,
        from: this.accounts[0],
      })
      .then(function (res) {
        if (res) {
          console.log(res);
          return "success";
        }
      })
      .catch(err => {
        return err;
      });
    return data;
  },
};

export default contract_obj;
