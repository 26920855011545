import React, { useEffect } from "react";
import { useState } from "react";
import {
  makeStyles,
  Dialog,
  ThemeProvider,
  createMuiTheme,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { VscChromeClose } from "react-icons/vsc";
import { HiMinus, HiPlus } from "react-icons/hi";
import { Button } from "@material-ui/core";
import Background from "../../assets/images/popupBg.png";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import metacoinConfigCeto from "../../metacoin-config-ceto";
import "./modal_.css";
import Axios from "axios";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#C90001",
    },
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    background: "#272750",
    // overflow: "auto",

    backgroundSize: "cover",
    borderRadius: "72px",
    justifySelf: "center",
    [theme.breakpoints.down("sm")]: {
      width: "320px",
    },
  },
  content: {
    paddingTop: "18px",
    paddingRight: "70px",
    paddingLeft: "70px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 22px",
    },
  },
  topbar: {
    display: "flex",
    flexDirection: "row",
    color: "white",
  },
  title: {
    textAlign: "left",
    flex: 9,
    color: "white",
    fontSize: "40px",
    lineHeight: "60px",
    fontWeight: "400",
    fontFamily: "Poppins",
  },
  icon: {
    flex: 1,
    fontSize: "30px",
  },

  iconCursor: {
    cursor: "pointer",
    margin: "auto",
  },

  ticketCount: {
    // width: "40px",
    display: "inline-block",
    textAlign: "center",
    marginRight: "10px",
    marginLeft: "10px",
    borderRadius: "6px",
    border: " 1px solid #FF5C34",
    background: "transparent",
    color: "#FF5C34",
    display: "flex",
    padding: "0 10px",
  },

  table: {
    marginTop: "40px",
    // marginRight: "45px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  row: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    marginBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
    },
  },

  column1: {
    flex: 1,
    textAlign: "left",
  },
  column2: {
    display: "flex",
    textAlign: "right",
  },
  hr1: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    display: "block",
    height: "0.3px",
    marginTop: "0px",
    marginDown: "5px",
    position: "relative",
  },
  buttons: {
    width: "100%",
    display: "flex",
    marginTop: "10px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  button: {
    margin: "10px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#FFFFFF",
    align: "center",
    textAlign: "center",
    borderRadius: "100px",
    width: "180px",
    height: "50px",
    background: "linear-gradient(270deg, #E35B30 -23.9%, #E33045 80.04%)",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      fontWeight: 500,
      fontSize: "16px",
    },
    "&:hover": {
      background: "linear-gradient(90deg, #d02b2c 33.1%, #c34f2a 100%)",
    },
    "&:disabled": {
      color: "#FFFFFF",
      opacity: "0.5",
    },
  },
  sec_button: {
    margin: "10px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "30px",
    align: "center",
    textAlign: "center",
    borderRadius: "100px",
    width: "180px",
    height: "50px",
    border: " 1px solid #FF5C34",
    backgroundColor: "transparent",
    color: "#FF5C34",

    "&:hover": {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      fontWeight: 500,
      fontSize: "16px",
    },
  },
  tabs: {
    width: "100%",
    display: "flex",
  },
  tab_btn1: {
    width: "50%",
    background: "#272750",
    boxShadow: "none",
    borderRadius: "0",
    color: "#FF5C34",
    fontSize: "21px",
    fontWeight: "normal",
    textTransform: "initial",

    "&:hover": {
      background: "#272750",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
  tab_btn2: {
    width: "50%",
    background: "#2E2E52",
    boxShadow: "none",
    borderRadius: "0",
    color: "#ffffff99",
    fontSize: "21px",
    fontWeight: "normal",
    textTransform: "initial",

    "&:hover": {
      background: "#2E2E52",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
  txt22: {
    fontSize: "16px",
    fontWeight: "200",
    color: "white",
    marginBottom: "1.5em",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  txt223: {
    fontSize: "16px",
    fontWeight: "200",
    color: "white",
    marginBottom: "1.5em",
    textAlign: "center",
  },
  a: {
    textDecoration: "underline",
    color: "#FF5C34",
    fontWeight: "300",
    "&:hover": {
      color: "#FF5C34",
    },
  },
  orange: {
    fontSize: "18px",
    fontWeight: "300",
    color: "#FF7629",
  },
  disable_: {
    opacity: "0.8",
  },
  warn_: {
    textAlign: "center",
    color: "white",
    opacity: "0.7",
  },
  row_: {
    margin: "1em 0",
  },
  outlined: {
    color: "white",
    padding: "0 0 0 5px",
    fontSize: "20px",
  },
  outlined_: {
    backgroundColor: "#7777aa75",
    color: "white",
    padding: "0 0 0 5px",
    fontSize: "15px",
    borderRadius: "3px",
    height: "75%",
    margin: "auto 0 auto 10px",

    "&:hover": {
      backgroundColor: "#7777aa75",
    },
  },
  count: {
    margin: "auto",
  },
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Modal = ({
  open,
  setShowModal,
  buyTicket,
  approved,
  approve,
  allowance,
  processing,
  contractData,
  userTickets,
  prob,
  node,
}) => {
  const classes = useStyles();

  const [ticketCount, setTicketCount] = useState(5);
  const [ticketPrice, setTicketPrice] = useState();
  const [initialTrxPrice, setInitialTrxPrice] = useState();
  const [initialCetoPrice, setInitialCetoPrice] = useState();

  const [calculating, setCalculating] = useState(false);
  const [transactionFee, setTransactionFee] = useState(0.5);
  const [tab, setTab] = useState(1);

  const action = () => {
    if (tab == 2 || approved || ticketPrice * ticketCount <= allowance / 1e6) {
      tab == 1 ? buyTicket(ticketCount) : buyTicket(ticketCount, ticketPrice);
    } else {
      approve(ticketCount);
    }
  };
  useEffect(() => {
    // console.log(contractData);
    if (contractData) {
      if (tab == 1) {
        setInitialCetoPrice(contractData.ticketPrice / 1e6);
        setTicketPrice(contractData.ticketPrice / 1e6);
      } else {
        getTronPrice(1);
        getTronPrice(ticketCount);
      }
    }
  }, [contractData, tab]);
  const getTronPrice = async tickets => {
    setCalculating(true);
    console.log(ticketCount, ticketPrice);
    var config = {
      method: "get",
      url: `https://api.crystalelephant.net/api/buyprice/?cetoValue=${
        contractData.ticketPrice * tickets
      }&contract_address=${metacoinConfigCeto[node].contractAddressBase}`,
    };
    Axios(config)
      .then(function (res) {
        console.log(res, "ticketPrice");
        var price = res.data.data;
        price = price + price / 10;
        setTicketPrice(price);
        if (tickets == 1) {
          setInitialTrxPrice(price);
        }
        setCalculating(false);
        return price;
      })
      .catch(function (error) {
        console.log(error);
        setCalculating(false);
      });
  };
  useEffect(() => {
    // console.log(contractData);

    if (tab === 2) {
      var price = getTronPrice(ticketCount);
    }
  }, [ticketCount, tab]);

  return (
    <div className={`modal ${open ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className={classes.root}>
          <div className={classes.tabs}>
            <Button
              variant="contained"
              size="large"
              className={tab == 1 ? classes.tab_btn1 : classes.tab_btn2}
              onClick={() => setTab(1)}
            >
              Buy using CETO
            </Button>{" "}
            <Button
              variant="contained"
              size="large"
              className={tab == 2 ? classes.tab_btn1 : classes.tab_btn2}
              onClick={() => setTab(2)}
            >
              Buy using TRON
            </Button>
          </div>
          <div className={classes.content}>
            <div className={classes.table}>
              <div className={classes.row}>
                <div className={classes.column1}>Ticket Price</div>
                <div className={classes.column2}>
                  {tab == 2 && "~"}
                  {tab == 1
                    ? parseFloat(initialCetoPrice).toFixed(2)
                    : parseFloat(initialTrxPrice).toFixed(2)}{" "}
                  {tab == 1 ? "CETO" : "TRX"}/Ticket
                </div>
              </div>

              <div className={classes.row}>
                <div className={classes.column1}># of Tickets</div>
                <div className={classes.column2}>
                  <HiMinus
                    className={`${classes.iconCursor} ${
                      ticketCount == 1 && classes.disable_
                    }`}
                    onClick={() => {
                      ticketCount > 1 && setTicketCount(ticketCount - 1);
                    }}
                    style={{
                      opacity: ticketCount == 1 && "0.2",
                      cursor: ticketCount == 1 && "auto",
                    }}
                  />
                  {!calculating ? (
                    <div className={classes.ticketCount}>
                      {" "}
                      <span className={classes.count}>{ticketCount}</span>{" "}
                      <IconButton
                        className={classes.outlined_}
                        onClick={() => setTicketCount(30)}
                      >
                        max{" "}
                        <ArrowUpwardIcon
                          className={classes.outlined}
                        ></ArrowUpwardIcon>
                      </IconButton>
                    </div>
                  ) : (
                    <CircularProgress
                      style={{
                        color: "white",
                        width: "25px",
                        height: "25px",
                        margin: "0 1em",
                      }}
                    />
                  )}
                  <HiPlus
                    className={`${classes.iconCursor} ${
                      ticketCount >= 30 && classes.disable_
                    }`}
                    style={{
                      opacity: ticketCount >= 30 && "0.2",
                      cursor: ticketCount >= 30 && "auto",
                    }}
                    onClick={() => {
                      ticketCount < 30 && setTicketCount(ticketCount + 1);
                    }}
                  />
                </div>
              </div>

              <hr className={classes.hr1} />

              <div className={classes.row}>
                <div className={classes.column1}>Total Cost</div>
                <div className={[classes.baseFont, classes.column2]}>
                  {tab == 1 && ticketPrice && ticketCount
                    ? (ticketPrice * ticketCount).toFixed(2)
                    : ticketPrice && ticketPrice.toFixed(2)}{" "}
                  {tab == 1 ? "CETO" : "TRX"}
                </div>
              </div>
              <div className={classes.row_}>
                <div className={classes.warn_}>
                  Maximum 15 tickets allowed per transaction.
                </div>
                <div className={classes.warn_}>
                  At least {ticketCount > 15 ? "300" : "100"} TRX required for
                  gas fee.
                </div>
                {tab == 1 && (
                  <div className={classes.warn_}>
                    Approve transaction, then click Enter Event to pay.
                  </div>
                )}
              </div>
              <div className={classes.row}>
                <div className={classes.buttons}>
                  <ThemeProvider theme={theme}>
                    <Button
                      className={classes.sec_button}
                      color="secondary"
                      variant="contained"
                      onClick={() => setShowModal(false)}
                    >
                      CANCEL
                    </Button>
                    <Button
                      className={classes.button}
                      color="secondary"
                      variant="contained"
                      onClick={() => !calculating && action()}
                      disabled={calculating || ticketCount == 0}
                    >
                      {processing == 0 ? (
                        approved ||
                        tab == 2 ||
                        ticketPrice * ticketCount <= allowance / 1e6 ? (
                          "Enter Event"
                        ) : (
                          "Approve"
                        )
                      ) : (
                        <CircularProgress
                          style={{
                            color: "white",
                            width: "25px",
                            height: "25px",
                          }}
                        />
                      )}
                    </Button>
                  </ThemeProvider>
                </div>
              </div>
              {/* <div className={classes.txt22}>
                {contractData &&
                contractData.gameStarted &&
                userTickets &&
                userTickets >= 1 ? (
                  <div className={classes.txt22}>
                    You have{" "}
                    <span className={classes.orange}>
                      {(contractData && contractData.participantsCount < 3) ||
                        (contractData &&
                          userTickets &&
                          contractData.participantsCount == userTickets)
                        ? "100"
                        : prob && prob.toFixed(2)}
                      %
                    </span>{" "}
                    chance of winning with your
                    <span className={classes.orange}> {userTickets}</span>{" "}
                    {userTickets && userTickets > 1 ? "Tickets" : "Ticket"}
                  </div>
                ) : null}
              </div> */}
              {tab == 1 && (
                <div className={classes.txt223}>
                  Don't have enough CETO?{" "}
                  <a
                    href="https://crystalelephant.net/ceto/buy"
                    target="_blank"
                    className={classes.a}
                  >
                    Buy now
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
