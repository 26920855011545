import "bootstrap/dist/css/bootstrap-theme.css";
import "bootstrap/dist/css/bootstrap.css";
import "bulma/css/bulma.css";
import localForage from "localforage";
import React, { lazy, Suspense, Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { hydrate } from "react-dom";
import { Provider } from "react-redux";
import persistStore from "redux-persist/lib/persistStore";
import { setAuthorizationToken } from "./config/axios";
import { routes } from "./Routes";
import configureStore from "./store/configureStore";
// import "./tronweb.js";
import Contract_obj from "./getTronweb.js";
import "./metacoin-config.js";
import browserHistory from "../src/history";
import "../src/styles/globals.css";

const BigTent = lazy(() => import("./container/Landing"));
const CircusMaximus = lazy(() => import("./container/CircusMaximus"));
const CoinFlip = lazy(() => import("./container/CoinFlip"));
const Dice = lazy(() => import("./container/Dice"));
const DeclareResults = lazy(() => import("./container/DeclareResults"));
const StartGame = lazy(() => import("./container/StartGame"));
const RollWithIt = lazy(() => import("./container/RollWithIt"));

const store = configureStore(window.__REDUX_STATE__);
const props = {
  store,
};
class App extends Component {
  constructor(appProps) {
    super(appProps);
    this.state = { rehydrated: false };
  }
  componentWillMount() {
    persistStore(
      store,
      {
        storage: localForage,
        blacklist: [],
      },
      () => {
        // store.dispatch({
        //   type: TURN_ON_DOB_MODAL
        // });
        console.log("app remounted");
        const { user } = store.getState();
        setAuthorizationToken(user);
        this.setState({
          rehydrated: true,
        });
      }
    );
  }

  render() {
    if (!this.state.rehydrated) return null;
    // return <Provider store={store}>{routes(props)}</Provider>
    return (
      <Provider store={store}>
        <Router
          history={browserHistory}
          onUpdate={() => window.scrollTo(0, 0)}
          props={props}
        >
          <Suspense fallback={<div></div>}>
            <Switch>
              <Route exact path="/" component={BigTent} />
              <Route exact path="/big-tent" component={BigTent} />
              <Route exact path="/coin-flip" component={CoinFlip} />
              <Route exact path="/dice" component={Dice} />
              <Route exact path="/start-game" component={StartGame} />
              <Route exact path="/roll-with-it" component={RollWithIt} />
              <Route exact path="/declare-results" component={DeclareResults} />
            </Switch>
          </Suspense>
        </Router>
      </Provider>
    );
  }
}

hydrate(<App />, document.getElementById("root"));

// {           //  <h1> {`${props.name}`}</h1>
//  <img className="img" src="/images/pic.jpg" alt="bike" />}
