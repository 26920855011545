var metacoinConfig = {
  mainnet: {
    contractAddress: "410b84765bdcf9e29777e2444f7aae6bd5921de20b",
    contractAddressBase: "TB277p9vW7B7rMe6rWotgbR5idzxV5eDjM",
    secondaryAccount: "TW7ibcJbWSKDuqrkScaGf1KPRpQxn6C7J1",
    privateKey:
      "dcf681731f143736234292445e1bd7e4cb7f3c98d6381782e7567362a860b959",
    fullHost: "https://api.trongrid.io",
    url: "https://tronscan.org",
  },
  shasta: {
    contractAddress: "41ea46a528240ca7ebe8b0fc384a920e332d1de6c6",
    contractAddressBase: "TXKwkTWpdcWTqLFRGMGxeJz9FFxv6maz2Q",
    secondaryAccount: "TW7ibcJbWSKDuqrkScaGf1KPRpQxn6C7J1",
    privateKey:
      "dcf681731f143736234292445e1bd7e4cb7f3c98d6381782e7567362a860b959",
    fullHost: "https://api.shasta.trongrid.io",
    url: "https://shasta.tronscan.org",
  },
  // private: {
  //   contractAddress: "41a584cbfa0ae946e047aceb32bd08e3b3e360c3f6",
  //   contractAddressBase: "TR4PabNEc6LERVKqrugJXHvuCMq9fNKgGJ",
  //   secondaryAccount: "TTYWmz3CA4hZ4gVx3oZpnscPTanwoA2YXB",

  //   fullHost: "https://node.crystalelephant.net",
  //   url: "https://node.crystalelephant.net",
  // },
  private: {
    contractAddress: "410e0e76ff0f7f9b22b2f9d47624c2f6ac5a4de54e",
    contractAddressBase: "TBFXnn7SPgcdpn9pGxkSRXjvDkL1b4sv3z",
    secondaryAccount: "TW7ibcJbWSKDuqrkScaGf1KPRpQxn6C7J1",

    fullHost: "https://api.nileex.io",
    url: "https://api.nileex.io",
  },
};

export default metacoinConfig;
