import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { makeStyles, Zoom } from "@material-ui/core";
import { Style } from "./modal_.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Zoom
      in={true}
      ref={ref}
      {...props}
      style={{ transitionDelay: true ? "500ms" : "0ms" }}
    ></Zoom>
  );
});
const useStyles = makeStyles(theme => ({
  root: {
    backgroundSize: "cover",
    borderRadius: "72px",
    justifySelf: "center",
  },
  box: {
    height: "100%",
  },
}));
export default function WaitingModal({ open, setOpen }) {
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Style>
      <Dialog
        open={open}
        className={classes.root}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ borderRadius: "29px" }}
      >
        <div className={classes.box}>
          <img
            src="https://i.giphy.com/media/3ohc14f3wq6c8NNwVG/giphy.webp"
            style={{ width: "100%", height: "100%" }}
          ></img>
        </div>
      </Dialog>
    </Style>
  );
}
