import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

/////images
import ceto from "../../assets/v2/ceto_white_img.png";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: "6vh 8vw",
    textAlign: "center",
    color: "white",
    backgroundSize: "cover",
  },
  top_: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  },
  txt1: {
    fontWeight: "300",
    fontSize: "25px",
    margin: "auto 0",

    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5em",
    },
  },
  section1: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "1em",
    },
  },
  box: {
    background: "#1A1A43",
    borderRadius: "15px",
    width: "30%",
    padding: "1.5em 1em 1em",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "2em",
      borderRadius: "4.5px",

      fontSize: "15px",
    },
  },
  top: {
    display: "flex",
    marginBottom: "1em",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  bottom: {
    display: "flex",
    justifyContent: "space-between",
  },
  game: {
    background: "#253261",
    borderRadius: "50px",
    fontSize: "17px",
    padding: "2px 30px",
    [theme.breakpoints.down("xs")]: {
      width: "fit-content",
      marginBottom: "1em",
    },
  },
  date: {
    textAlign: "left",
    fontSize: "20px",
    fontWeight: "300",
  },
  amount: {
    fontSize: "37px",
    fontWeight: "400",
    color: "#F99742",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
  },
  ceto: {
    fontSize: "35px",
    fontWeight: "300",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
  },
  img: {
    height: "45px",
    width: "34px",
    margin: "auto 20px",
    [theme.breakpoints.down("sm")]: {
      width: "22px",
      height: "29px",
    },
  },
  address: {
    fontSize: "20px",
    fontWeight: "300",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  mid: {
    marginBottom: "2em",
  },
}));

export default function Winners({ contractData }) {
  const classes = useStyles();
  const [winners, setWinners] = useState([]);

  const hexToBase = hex => {
    const TronWeb = require("tronweb");

    const tronWeb = new TronWeb({
      fullHost: "https://api.trongrid.io",
      eventServer: "https://api.trongrid.io",
    });
    return tronWeb.address.fromHex(hex);
  };
  const deductFee = value => {
    var taxedVal = value - value / 10;
    return taxedVal;
  };
  useEffect(() => {
    if (contractData && contractData.result.gameNumber >= 1) {
      // console.log(contractData.recentWinners[0].toString().slice(0, 6));
      setWinners([
        {
          account:
            hexToBase(contractData.result.firstWinner).toString().slice(0, 6) +
            "...." +
            hexToBase(contractData.result.firstWinner).toString().slice(-3),
          amount: (parseInt(contractData.result.firstPrize) / 1e6).toFixed(2),
          game_number: contractData.result.gameNumber,
          date: contractData.block_timestamp / 1e3,
        },
        {
          account:
            hexToBase(contractData.result.secondtWinner)
              .toString()
              .slice(0, 6) +
            "...." +
            hexToBase(contractData.result.secondtWinner).toString().slice(-3),
          amount: (parseInt(contractData.result.secondPrize) / 1e6).toFixed(2),
          game_number: contractData.result.gameNumber,
          date: contractData.block_timestamp / 1e3,
        },
        {
          account:
            hexToBase(contractData.result.thirdWinner).toString().slice(0, 6) +
            "...." +
            hexToBase(contractData.result.thirdWinner).toString().slice(-3),
          amount: (parseInt(contractData.result.thirdPrize) / 1e6).toFixed(2),
          game_number: contractData.result.gameNumber,
          date: contractData.block_timestamp / 1e3,
        },
      ]);
    } else {
      setWinners([
        {
          account: "410000000000000000000000000000000000000000".slice(0, 12),
          amount: 0,
          game_number: 0,
          date: 0,
        },
        {
          account: "410000000000000000000000000000000000000000".slice(0, 12),
          amount: 0,
          game_number: 0,
          date: 0,
        },
        {
          account: "410000000000000000000000000000000000000000".slice(0, 12),
          amount: 0,
          game_number: 0,
          date: 0,
        },
      ]);
    }
  }, [contractData]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.top_}>
          <div className={classes.txt1}>Recent Winners</div>
        </div>
        <div className={classes.section1}>
          {winners.map(winner => {
            return (
              <div className={classes.box}>
                <div className={classes.top}>
                  <div className={classes.address}>{winner.account}</div>
                  <div className={classes.game}>
                    Event #{winner.game_number}
                  </div>
                </div>
                <div className={classes.mid}>
                  <div className={classes.date}>
                    {new Date(parseInt(winner.date * 1000))
                      .toDateString()
                      .slice(0, 10)}{" "}
                  </div>
                </div>
                <div className={classes.bottom}>
                  <div className={classes.amount}>
                    {deductFee(winner.amount).toFixed(2)}{" "}
                    <span className={classes.ceto}>CETO</span>
                  </div>
                  <img className={classes.img} src={ceto}></img>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
