import { CURRENCIES, SET_ENV, RESULTS_DECLARED } from "../actions/types";

const initialState = {
  currencies: {},
  contract_instace: null,
  accounts: [],
  contract_balance: null,
  results_declared: 0,
};

export default function UtilsReducer(state = initialState, action) {
  switch (action.type) {
    case CURRENCIES:
      // console.log("success");
      var newState = {
        ...state,
        currencies: action.payload,
      };
      return newState;

    case RESULTS_DECLARED:
      var newState = {
        ...state,
        results_declared: action.payload,
      };
      return newState;
    case SET_ENV:
      // console.log(action.payload,"success");
      var newState = {
        ...state,
        contract_instace: action.payload.instance,
        contract_balance: action.payload.contract_balance,
        accounts: action.payload.accounts,
      };
      return newState;
    default:
      return state;
  }
}
