import React from "react";
import { useState } from "react";

import { makeStyles } from "@material-ui/core";
import Plus from "../../assets/images/Icons/plus.png";
import Minus from "../../assets/images/Icons/minus.png";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
  root: {
    background: "#0F0F33",
    color: "white",
    fontSize: "15px",
    paddingBottom: "5em",
  },
  h1: {
    marginBottom: "2em",
    fontFamily: "Poppins",
    fontSize: "30px",
    color: "white",
    textAlign: "center",
    paddingTop: "3vh",
    lineHeight: "51px",
    fontWeight: "300",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "50px",
      fontSize: "26px",
      fontWeight: "400",
      paddingTop: "40px",
    },
  },
  questionContainer: {
    background: "#1F1F4A",
    borderRadius: "4px",
    color: "white",
    marginBottom: "1em",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
      fontSize: "1.1em",
    },
  },
  question: {
    flex: 10,
    fontSize: "18.5px",
    padding: "20px",
    paddingLeft: "25px",
    fontWeight: "400px",
    lineHeight: "30px",
    [theme.breakpoints.down("xs")]: {
      padding: "2px",
      fontSize: "1em",
    },
  },
  icon: {
    margin: "12px",
    width: "32px",
    height: "40px",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      margin: "0px",
      width: "24px",
      height: "32px",
    },
  },
  answer: {
    paddingLeft: "3vw",
    paddingRight: "4vw",
    paddingTop: "20px",
    paddingBottom: "10px",
    marginBottom: "20px",

    fontFamily: "Poppins",
    fontFtyle: "normal",
    fontWeight: 300,
    fontSize: "1.2em",

    lineHeight: "36px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.1em",
      lineHeight: "25px",
    },
  },

  container: {
    margin: "0 8vw",
  },

  margin: {
    margin: theme.spacing("0"),
  },
}));

const questions = [
  "How does it work?",
  "What is a Guaranteed Prize Goal (GPG)?",
  "How much do promotional partners get?",
  "What happens to promotional partners when the GPG isn’t reached?",
  "Why is there a minimum requirement of entries for a Big Tent Event drawing to commence?",
  "Is there a limit to tickets to a Big Tent Event I can buy?",
  "Can I play Big Tent on ECETO and/or BCETO?",
  "Can I benefit from Big Tent without playing?",
  "Are non-CETO holders buying Big Tent tickets hit with a 10 percent tax that stays within the Big Tent game pool or that goes into CETO or both?",
  "Existing CETO holders can just exchange for tickets with the 10 percent hit but are there transaction fees?",
  "I would like to be a promotional partner. What do I do?",
];

const answer1 = (
  <div>
    You enter for a chance to win one of the main prizes. If you aren’t a big
    winner, you share proportionally in the dividends earned on the Big Tent
    Event’s prize pool account.
    <br />
    &nbsp; - &nbsp;Example: 1000 TRX in dividends earned from Big Tent Event
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;You have 5 tickets out of 50 to
    the Event (10%)
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;You do not win one of the
    prizes
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;You receive 90 TRX in dividend
    money back (100 TRX - 10 TRX transaction fee)
  </div>
);

const answer5 = (
  <div>
    We depend on our promotional partners to help us achieve above and beyond
    Guaranteed Prize Goals. In the event our combined efforts fall short, the
    CETO Foundation will ensure that the 67% of the declared GPG that is used to
    pay prizes is fully covered. Any funds over 67% but under 100% of the GPG
    will go to the promotional partner.
    <br />
    Example 1: 100 CETO guarantee, 50 CETO collected CETO Foundation covers the
    17 CETO difference for prizes
    <br />
    Example 2: 100 CETO guarantee, 80 CETO collected 67 CETO in prizes covered
    partners receive 13 CETO difference
    <br />
  </div>
);
const answers = [
  answer1,

  "Every Big Tent Event starts with a Guaranteed Prize Goal and start date. If the GPG amount is not reached in time for the start date, then the drawing begins regardless, with the prize money guaranteed for the prize payouts. " +
    "Once the GPG amount is reached, a 24 hour countdown begins for the drawing. 50% of the entries made over the GPG go to the promotional partner, 25% back to the prize pool, and 25% to funding future Big Tent Events.",

  "Promotional partners receive 33% of the GPG, with prizes being paid out to Event ticketholders of the remaining 67%.",
  answer5,
  "We establish a very low bar of required entries in order to execute a drawing with enough randomness to give entrants a fair chance at prize money.",
  "At this time, there is no ticket limit.",
  "When there are Big Tent Events available on our various blockchain versions of CETO, you may play them.",
  "Sure - just own some CETO! As a Big Tent Event’s prize pool is held in a CETO wallet, the 10% transaction fees it generates will be spread out among all CETO owners.",
  "Non-CETO holders must own CETO in order to buy a Big Tent ticket - it runs on CETO only. That 10 percent transaction fee goes into the CETO contract as dividends to all CETO tokenholders.",
  "Existing CETO holders can buy a Big Tent ticket penalty-free: it's the same as if executing a transfer to another wallet.",
  "Please send us an email at bigtent@crystalelephant.net and a representative will coordinate with you to schedule a Big Tent Event.",
];

export default function FAQ() {
  const classes = useStyles();
  const clickedData = {
    icon1: false,
    icon2: false,
    icon3: false,
    icon4: false,
    icon5: false,
    icon6: false,
    icon7: false,
    icon8: false,
    icon9: false,
  };
  const [clicked, setClicked] = useState(clickedData);

  return (
    <>
      <div className={classes.root} id="faq">
        <div className={classes.container}>
          <div>
            <h1 className={classes.h1}>Frequently Asked Questions</h1>
          </div>

          <div>
            {questions.map((question, index) => {
              return (
                <div key={index}>
                  <div className={classes.questionContainer}>
                    <div className={classes.question}>{question}</div>
                    {clicked[`icon${index}`] ? (
                      <IconButton
                        aria-label="delete"
                        className={classes.margin}
                        size="small"
                        onClick={() =>
                          setClicked({ ...clicked, [`icon${index}`]: false })
                        }
                      >
                        <img
                          src={Minus}
                          className={classes.icon}
                          name="icon1"
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="delete"
                        className={classes.margin}
                        size="small"
                        onClick={e =>
                          setClicked({ ...clicked, [`icon${index}`]: true })
                        }
                      >
                        <img
                          src={Plus}
                          className={classes.icon}
                          // dataName="icon1"
                        />
                      </IconButton>
                    )}
                  </div>
                  {clicked[`icon${index}`] && (
                    <div className={classes.answer}>{answers[index]}</div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
