import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

/////images
import logo from "../../public/prize.png";
import bg from "../../public/BGS/prizes.png";
import money from "../../assets/v2/money.png";
import party from "../../public/party.png";
import bricks from "../../assets/v2/bricks.png";
import bag from "../../assets/v2/bag.png";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: "6vh 8vw",
    textAlign: "center",
    color: "white",
    backgroundSize: "cover",
    position: "relative",
  },
  imgRight1: {
    right: "8vw",
    top: "10vh",
    position: "absolute",
    zIndex: 6,
    [theme.breakpoints.down("sm")]: {
      transform: "scale(0.5,0.5)",
      top: "15%",
      zIndex: 1,
    },
  },
  top_: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  red: {
    background: "#C90001",
    borderRadius: "50%",
    padding: "0.7em",

    width: "100px",
    height: "100px",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      width: "50px",
      height: "50px",
    },
  },
  white: {
    background: "#1A1A43",
    borderRadius: "50%",
    padding: "0.7em",
    width: "54px",
    height: "54px",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
      width: "50px",
      height: "50px",
    },
  },
  logo_: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      transform: "scale(0.75)",
    },
  },
  txt1: {
    fontWeight: "300",
    fontSize: "25px",
    margin: "auto 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      textAlign: "center",
    },
  },
  txt11: {
    fontWeight: "300",
    fontSize: "21px",
    margin: "auto 1em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      textAlign: "left",
    },
  },
  everyone: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  txt2: {
    fontSize: "1.5em",
    marginTop: "2em",
  },
  section1: {
    display: "flex",
    margin: "6vh 0 0",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
  },

  section2: {
    display: "flex",
    justifyContent: "center",
    margin: "3vh 0",
    width: "80%",
    textAlign: "left",
    zIndex: 5,

    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
      flexDirection: "column",
      margin: "5em auto",
      zIndex: 2,
    },
  },
  section3: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      flexDirection: "column",
      width: "100%",
      margin: 0,
      textAlign: "center",
      zIndex: 2,
    },
  },
  section21: {
    background: "#1A1A43",
    padding: "5vh 5vw",
    width: "100%",
  },
  txt5: {
    fontSize: "1.2em",
    fontWeight: "300",
    color: "#CFF4FF",

    // borderRight: "1px solid #C7EDFA",
    // padding: "0 3vw",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4em",
      padding: "3vh 3vw",
      width: "100%",
      borderBottom: "1px solid #C7EDFA",
      borderRight: 0,
    },
  },

  txt6: {
    width: "50%",
    fontSize: "19px",
    fontWeight: "300",
    color: "#CFF4FF",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.4em",
      padding: "3vh 3vw",
      width: "100%",
    },
  },
  txt7: {
    width: "100%",
  },
  bold: {
    fontWeight: "500",
    color: "white",
  },
  cntainer: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "40px",
    },
  },
  imgRight2: {
    position: "absolute",
    right: "0",
    bottom: "0",
    [theme.breakpoints.down("sm")]: {
      top: "90%",
      transform: "scale(0.5)",
      left: "25%",
    },
  },
  line: {
    margin: "0 3em",
    width: "1px",
    background: "linear-gradient(to bottom, #ffffff00,#63c7ff57,#ffffff00)",
  },
}));

export default function Prizes() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <img src={bag} className={classes.imgRight1}></img>
        <div className={classes.top_}>
          <div className={classes.txt1}>Prizes</div>
        </div>
        <div className={classes.section1}>
          <div className={classes.white}>
            <img src={money} className={classes.logo_}></img>
          </div>
          <div className={classes.txt11}>
            A Raffle where winners win
            <span className={classes.bold}> BIG </span>
            and the rest share
            <span className={classes.bold}> Divs</span>
          </div>
        </div>
        <div className={`${classes.section2} ${classes.section21}`}>
          <div className={classes.txt5}>
            Once <span className={classes.bold}> Guaranteed Prize Goal</span>{" "}
            reached, the drawing happens 24 hours later
          </div>
          <div className={classes.line}></div>

          <div className={classes.txt6}>
            Proceeds above guarantee go to{" "}
            <span className={classes.bold}>Prize Pool</span>, promotional
            partners, and a future{" "}
            <span className={classes.bold}>Big Tent Event</span>
          </div>
        </div>
        <div className={classes.cntainer}>
          <img src={bricks} className={classes.imgRight2}></img>

          <div className={classes.section1}>
            <div className={classes.white}>
              <img src={party} className={classes.logo_}></img>
            </div>
            <div className={`${classes.txt11} ${classes.everyone}`}>
              Everyone<span className={classes.bold}> WINS</span>
            </div>
          </div>
          <div className={`${classes.section2} ${classes.section3}`}>
            <div className={`${classes.txt6} ${classes.txt7}`}>
              <span className={classes.bold}>Winners are drawn</span>, the rest
              split dividends
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
}
