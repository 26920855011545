import {Box, Button, Grid, makeStyles, Typography} from "@material-ui/core";
import React from "react";
import bg from "../../public/BGS/steps.png";
import one from "../../assets/v2/tron_icon.png";
import two from "../../assets/v2/click.png";
import three from "../../assets/v2/confirm.png";
import four from "../../assets/v2/enter.png";
import logo from "../../assets/v2/logo.png";
import arr_1 from "../../assets/v2/green_arrow.png";
import arr_2 from "../../public/Arrow 2.png";
import arr_3 from "../../public/Arrow 3.png";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: "8vh 8vw",
    textAlign: "center",
    color: "white",
    backgroundSize: "cover",
  },
  top_: {
    display: "flex",
    margin: "0 0 4vh",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "20px",

      display: "flex",
      justifyContent: "center",
    },
  },
  red: {
    // background: "#C90001",
    borderRadius: "50%",
    padding: "1em",
    width: "100px",
    height: "100px",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      width: "50px",
      height: "50px",
    },
  },
  red_: {
    // background: "#C90001",
    position: 'relative',
    width: "80px",
    height: "80px",
    display: "flex",
    margin: "0 0 1em",
  },
  txt1: {
    fontWeight: "400",
    fontSize: "34px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5em",
      fontWeight: "400",
    },
  },
  txt2: {
    fontSize: "1.2em",
  },
  logo_: {
    margin: "auto",
  },
  steps: {
    display: "flex",
  },
  step: {
    margin: "5vh 0 2vh",
    width: "20vw",
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'justify',

      width: '100%',
    }
  },
  txt: {
    // margin: "auto",

    textAlign: "left",
    fontSize: "18px",
    fontWeight: "300",
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    }
  },
  bold: {
    fontWeight: 500,
  },
  txtt: {
    margin: "auto",
    fontSize: "1.2em",
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    }

  },
  icon_: {
    position: 'absolute',
    left: '0%',
    bottom: '0%',

  },
  arrows: {
    height: "10px",
    margin: "auto 4vw",
  },
  arrows_: {
    float: "right",
    marginRight: "25vw",
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }

  },
  l: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  s: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

export default function Steps() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <div className={classes.top_}>
          <div className={classes.txt1}>Step Right Up</div>
        </div>
        <div className={classes.l}>
          <div className={`${classes.steps}`}>
            <div className={classes.step}>
              <div className={classes.red_}>
                <img src={one} className={classes.icon_}></img>
              </div>
              <div className={classes.txt}>
                <span className={classes.bold}>Connect</span> with the TronLink/web wallet holding your CETO
              </div>
            </div>{" "}

            <img src={arr_1} className={classes.arrows}></img>{" "}
            <div className={classes.step}>
              <div className={classes.red_}>
                <img src={two} className={classes.icon_}></img>
              </div>
              <div className={classes.txt}><span className={classes.bold}>Select</span> how many tickets to buy</div>
            </div>{" "}
            <img src={arr_1} className={classes.arrows}></img>
            <div className={classes.step}>
              <div className={classes.red_}>
                <img src={three} className={classes.icon_}></img>
              </div>
              <div className={classes.txt}> <span className={classes.bold}>Click</span> Confirm</div>
            </div>{" "}
          </div>
          <div className={classes.steps}>
            <div className={classes.step}>
              <div className={classes.red_}>
                <img src={four} className={classes.icon_}></img>
              </div>
              <div className={classes.txt}>
                Click <span className={classes.bold}>Enter Event</span> to purchase <br></br>(make sure you have at
                least 20 TRX before you do!)
              </div>
            </div>{" "}
            <img src={arr_1} className={classes.arrows}></img>{" "}
            <div className={classes.step}>
              <div className={classes.red_}>
                <img src={logo} className={classes.icon_}></img>
              </div>
              <div className={classes.txt}>
                {" "}
                That’s it! Check your <span className={classes.bold}>Big Tent page </span> to keep up<br></br>{" "}
                with countdown start time, drawing time, prize pool, your
                winnings, and more!
              </div>
            </div>{" "}
          </div>{" "}
        </div>
        <div className={classes.s}>
          <div className={`${classes.steps}`}>
            <div className={classes.step}>
              <div className={classes.red_}>
                <img src={one} className={classes.icon_}></img>
              </div>
              <div className={classes.txt}>
                <b>Connect</b> with the TronLink/web wallet holding your CETO
              </div>
            </div>{" "}
          </div>
          <img src={arr_2} className={classes.arrows_}></img>
          <div className={`${classes.steps}`}>
            <div className={classes.step}>
              <div className={classes.red_}>
                <img src={two} className={classes.icon_}></img>
              </div>
              <div className={classes.txt}>Select how many tickets to buy</div>
            </div>{" "}
          </div>
          <img src={arr_2} className={classes.arrows_}></img>
          <div className={classes.steps}>
            <div className={classes.step}>
              <div className={classes.red_}>
                <img src={three} className={classes.icon_}></img>
              </div>
              <div className={classes.txt}>
                Click Enter Event to purchase <br></br>(make sure you have at
                least 20 TRX before you do!)
              </div>
            </div>{" "}
          </div>{" "}
          <img src={arr_2} className={classes.arrows_}></img>
          <div className={classes.steps}>
            <div className={classes.step}>
              <div className={classes.red_}>
                <img src={four} className={classes.icon_}></img>
              </div>
              <div className={classes.txt}> Click Confirm</div>
            </div>{" "}
          </div>
          <img src={arr_2} className={classes.arrows_}></img>
          <div className={classes.step}>
            <div className={classes.red_}>
              <img src={logo} className={classes.icon_}></img>
            </div>
            <div className={classes.txtt}>
              That’s it! Check your <b>Big Tent page </b> to keep up<br></br>{" "}
              with countdown start time, drawing time, prize pool, your
              winnings, and more!
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
