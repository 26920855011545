import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import bg1 from "../assets/images/background1.png";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Box, Container, Snackbar } from "@material-ui/core";
import Navbar from "../components/navbar/navbar.component";
import { connect } from "react-redux";
import * as actions from "../actions";
import Section1 from "../components/sections/Section1";
import Footer_ from "../components/sections/Section6";
import Partners from "../components/sections/Section5";
import Steps from "../components/sections/Section4";
import Rules from "../components/sections/Section3";
import Prizes from "../components/sections/Section2";
import Winners from "../components/sections/Section7";
import FAQ from "../components/sections/Section8";

import axiosInstance from "../config/axios";
import axios from "axios";
import Contract_obj from "../getTronweb";
import metacoinConfigCeto from "../metacoin-config-ceto";

import metacoinConfig from "../metacoin-config";
import Ceto_contract_obj from "../getCetoTronweb";
import WaitingModal from "../components/Modals/waiting";
import LostModal from "../components/Modals/lost";
import WinModal from "../components/Modals/win";
import ConnectionModal from "../components/connectionPopup";
import Modal from "../components/sections/Modal";
import RebateModal from "../components/sections/RebateModal";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    fontFamily: "Poppins",
    backgroundColor: "#0F0F33",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  navbar: {
    height: "93px",
    marginTop: "55px",
  },
}));

function Landing(props) {
  const classes = useStyles();
  const [processing, setProcessing] = useState(0);
  const [err_msg, set_err_msg] = useState("");
  const [err, handleErr] = useState(false);
  const [updated, setUpdated] = useState();
  const [loading, setLoading] = useState(true);
  const [approved, setApproved] = useState(false);
  const [allowance, setAllowance] = useState();
  const [contractData, setContractData] = useState();
  const [currencies, setCurrencies] = useState();
  const [showWaitingModal, setShowWaitingModal] = useState(false);
  const [showWinModal, setShowWinModal] = useState(false);
  const [winnerData, setWinnerData] = useState();
  const [prizeWon, setPrizeWon] = useState(0);
  const [userTickets, setUserTickets] = useState(0);
  const [shownav, setShowNav] = useState(false);
  const [resultsDeclared, setResultsDeclared] = useState();
  const [CETOBalance, setCETOBalance] = useState(0);
  const [node, setNode] = useState("mainnet");
  const [showLoseModal, setShowLoseModal] = useState(false);
  const [rebate, setRebate] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showRebateModal, setShowRebateModal] = useState(false);
  const [prob, setProb] = useState();
  const [userCeto, setUserCeto] = useState();
  const [success, handleSuccess] = useState(false);

  const [connectModal, setConnectModal] = useState(false);
  const [connection_issue, setConnectionIssue] = useState(1);
  const [timer, setTimer] = useState();
  const [live, setLive] = useState(false);
  const [positions, setPositions] = React.useState({
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal } = positions;
  const getContractInfo = async force => {
    // console.log(window.tronWeb.defaultAddress.base58, "rrr");

    if (window.tronWeb && window.tronWeb.defaultAddress) {
      var config = {
        method: "get",
        url: `https://api.crystalelephant.net/api/cache/?function=getUserTickets&force=${1}&network=${
          metacoinConfig[node].fullHost
        }&contract_address=${
          metacoinConfig[node].contractAddressBase
        }&sender_address=${window.tronWeb.defaultAddress.base58}`,
      };
      axios(config)
        .then(function (res) {
          setUserTickets(res.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
      var config = {
        method: "get",
        url: `https://api.crystalelephant.net/api/cache/?function=getCETORebateBalance&force=${1}&network=${
          metacoinConfig[node].fullHost
        }&contract_address=${
          metacoinConfig[node].contractAddressBase
        }&sender_address=${window.tronWeb.defaultAddress.base58}`,
      };
      axios(config)
        .then(function (res) {
          setRebate(res.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });

      var config = {
        method: "get",
        url: `https://api.crystalelephant.net/api/cache/?function=myTokens&expiry=30&force=${force}&network=${metacoinConfig[node].fullHost}&contract_address=${metacoinConfigCeto[node].contractAddressBase}&sender_address=${window.tronWeb.defaultAddress.base58}`,
      };
      axios(config)
        .then(function (res) {
          setUserCeto(res.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    var contractData_;
    var config = {
      method: "get",
      url: `https://api.crystalelephant.net/api/cache/?function=getEventData&force=${force}&network=${metacoinConfig[node].fullHost}&contract_address=${metacoinConfig[node].contractAddressBase}&sender_address=${metacoinConfig[node].secondaryAccount}`,
    };

    axios(config)
      .then(function (response) {
        var config = {
          method: "get",
          url: `https://api.crystalelephant.net/api/cache/?function=getPrizes&force=${force}&network=${metacoinConfig[node].fullHost}&contract_address=${metacoinConfig[node].contractAddressBase}&sender_address=${metacoinConfig[node].secondaryAccount}`,
        };
        axios(config)
          .then(function (res) {
            var config = {
              method: "get",
              url: `https://api.crystalelephant.net/api/prizes/?contract_address=${metacoinConfig[node].contractAddressBase}`,
            };
            axios(config).then(function (minPrizes_) {
              var minPrizes = minPrizes_.data.data;
              var prizes = res.data.data;
              var eventData = response.data.data;

              contractData_ = {
                participantsCount: eventData[1],
                startDate: parseInt(eventData[3]) + parseInt(eventData[5]),
                ticketPrice: eventData[0],
                gpp: eventData[2],
                countdownTimestamp: eventData[4],
                period: eventData[5],
                prizes: [
                  Math.max(prizes[0], minPrizes[0]),
                  Math.max(prizes[1], minPrizes[1]),
                  Math.max(prizes[2], minPrizes[2]),
                ],
                gameNumber: eventData[8],
                userTickets: 1,
                gameStarted: eventData[6],
                resultsDeclared: eventData[7],
              };
              setContractData(contractData_);
              // setResultsDeclared(eventData[7]);
              if (eventData[7]) {
                props.setResults(props.utils.results_declared + 1);
              } else if (eventData[6]) {
                props.setResults(0);
              }
              const currentDate = new Date();
              const now = parseInt(
                currentDate.getTime().toString().slice(0, -3)
              );
              var end = parseInt(eventData[3]) + parseInt(eventData[5]);

              if (end > 0 && end < now) {
                setShowWaitingModal(true);
              }
              var config = {
                method: "get",
                url: `https://api.crystalelephant.net/api/cache/?function=getCurrentCETOBalance&force=1&network=${metacoinConfig[node].fullHost}&contract_address=${metacoinConfig[node].contractAddressBase}&sender_address=${metacoinConfig[node].secondaryAccount}`,
              };

              axios(config)
                .then(function (response) {
                  var balanceData = response.data;
                  if (
                    parseInt(balanceData.data) > parseInt(contractData_.gpp)
                  ) {
                    setCETOBalance(balanceData.data);
                  } else {
                    setCETOBalance(parseInt(contractData_.gpp));
                  }
                })
                .catch(function (error) {
                  console.log(
                    "can't get the current CETO Balance because of: ",
                    error
                  );
                });
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });

    var config = {
      method: "get",
      url: "https://api.crystalelephant.net/api/currency/rates?currency=TRX",
    };
    axios(config)
      .then(function (response) {
        setCurrencies(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    var config = {
      method: "get",
      url: `https://api.crystalelephant.net/api/transaction/?filter=event_name=onGameEnd&contract_address=${metacoinConfig[node].contractAddressBase}&provider=${metacoinConfig[node].fullHost}`,
    };
    axios(config)
      .then(function (response) {
        setWinnerData(response.data.data[0], "result");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const coundownData = () => {
    const offset = 48600;
    const end_timestamp =
      contractData.countdownTimestamp != 0
        ? contractData.countdownTimestamp + contractData.period
        : contractData.startDate;

    var x = setInterval(function () {
      // Get today's date and time
      const currentDate = new Date();
      const now = parseInt(currentDate.getTime().toString().slice(0, -3));

      // Find the distance between now and the count down date
      var distance = end_timestamp - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (60 * 60 * 24));
      var hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
      var minutes = Math.floor((distance % (60 * 60)) / 60);
      var seconds = Math.floor(distance % 60);

      // Display the result in the element with id="demo"
      setTimer({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      });

      // If the count down is finished, write some text
      if (distance <= 0) {
        clearInterval(x);
        setTimer({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
        setShowWaitingModal(true);
        setLive(true);
      }
    }, 1000);
  };
  const mainFunc = () => {
    window.scrollTo(0, 0);

    window.addEventListener("load", async event => {
      var obj1 = setInterval(async () => {
        if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
          console.log("loadeded");
          // var node_ =
          //   window.tronWeb &&
          //   (window.tronWeb.fullNode.host == "https://api.trongrid.io"
          //     ? "mainnet"
          //     : "private");
          // setNode(node_);
          fetchData(0);
          clearInterval(obj1);
          setUpdated(updated + 1);
        }
      }, 2000);
    });
    // console.log("hii", document);
    document.addEventListener("readystatechange", event => {
      // console.log(event, "event");
      if (event.target.readyState == "complete") {
        // console.log(window, event, "windowww");
        fetchData(0);
        setTimeout(() => {
          if (
            !window.tronWeb ||
            (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
          ) {
            setLoading(false);
          } else if (
            window.tronWeb &&
            window.tronWeb.fullNode.host != metacoinConfig.fullHost
          ) {
            setLoading(false);
          }
        }, 3000);
      }
    });

    fetchData(0);
    getContractInfo(0);
  };
  const fetchData = async force => {
    window.tronWeb && (await Contract_obj.init(node));
    window.tronWeb && (await Ceto_contract_obj.init(node));
    if (Ceto_contract_obj.accounts[0]) {
      var config = {
        method: "get",
        url: `https://api.crystalelephant.net/api/cache/?function=allowance&force=${force}&arguments=${Ceto_contract_obj.accounts[0]},${Contract_obj.contractAddress}&network=${metacoinConfigCeto[node].fullHost}&contract_address=${metacoinConfigCeto[node].contractAddressBase}&sender_address=${Ceto_contract_obj.accounts[0]}`,
      };
      axios(config)
        .then(function (response) {
          setAllowance(parseInt(response.data.data.toString()));
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    mainFunc();
  }, []);
  const getResults = () => {
    var config = {
      method: "get",
      url: `https://api.crystalelephant.net/api/cache/?function=getEventData&force=1&network=${metacoinConfig[node].fullHost}&contract_address=${metacoinConfig[node].contractAddressBase}&sender_address=${metacoinConfig[node].secondaryAccount}`,
    };

    axios(config)
      .then(function (response) {
        var eventData = response.data.data;

        if (eventData[7]) {
          props.setResults(props.utils.results_declared + 1);

          var config = {
            method: "get",
            url: `https://api.crystalelephant.net/api/cache/?function=getWinners&force=1&network=${metacoinConfig[node].fullHost}&contract_address=${metacoinConfig[node].contractAddressBase}&sender_address=${metacoinConfig[node].secondaryAccount}`,
          };
          axios(config)
            .then(function (res) {
              var winners = res.data.data;
              setShowWaitingModal(false);

              if (
                window.tronWeb &&
                winners.includes(window.tronWeb.defaultAddress.base58)
              ) {
                setPrizeWon(
                  winners.indexOf(window.tronWeb.defaultAddress.base58) + 1
                );
                setShowWinModal(true);
              } else {
                setShowLoseModal(true);
              }
              getContractInfo();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    if (
      props.utils.results_declared == 1 ||
      (props.utils.results_declared == 1 && showWaitingModal)
    ) {
      console.log("should clear");
      getResults();
    }
  }, [props.utils.results_declared]);

  useEffect(() => {
    if (contractData && userTickets) {
      var prob_ =
        (userTickets / contractData.participantsCount +
          userTickets / (contractData.participantsCount - 1) +
          userTickets / (contractData.participantsCount - 2)) *
        100;
      setProb(prob_);
    }
    if (contractData) {
      const currentDate = new Date();
      const now = parseInt(currentDate.getTime().toString().slice(0, -3));

      if (
        (contractData.startDate > 0 &&
          now > contractData.startDate - contractData.period) ||
        (contractData.countdownTimestamp > 0 &&
          now > contractData.countdownTimestamp)
      ) {
        coundownData();
      }
    }
  }, [userTickets, contractData, updated]);

  useEffect(() => {
    window.onscroll = function () {
      var currentScrollPos = window.pageYOffset;
      // 20 is an arbitrary number here, just to make you think if you need the prevScrollpos variable:
      if (currentScrollPos > 100) {
        // I am using 'display' instead of 'top':
        setShowNav(true);
      } else {
        setShowNav(false);
      }
    };
  });
  useEffect(() => {
    getContractInfo(1);
  }, [updated]);
  const buyTicket = async (ticketCount, value) => {
    var ticketPrice = contractData.ticketPrice;

    try {
      var res = value
        ? await Contract_obj.buyWithTronFunc(ticketCount, value)
        : await Contract_obj.buyFunc(ticketCount);
      setProcessing(1);
      if (res != "success") {
        setProcessing(false);
        console.log("failed");

        set_err_msg(res.message ? res.message : res);

        handleErr(true);
      } else {
        console.log("success");
        setTimeout(async () => {
          setUpdated(updated + 1);
          setShowModal(false);
          setApproved(false);
          getContractInfo(1);
          setProcessing(0);
          handleSuccess(true);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      setProcessing(false);
      set_err_msg("Something went wrong!");
      handleErr(true);
    }
  };

  const withdrawRebate = async value => {
    if (rebate < value * 1e6) {
      set_err_msg("Rewards claimed must be more than 0");
      handleErr(true);
    } else {
      try {
        var res = await Contract_obj.withdrawRebate(value);
        setProcessing(1);
        console.log(res);
        if (res != "success") {
          setProcessing(false);
          console.log("failed");

          set_err_msg(res.message ? res.message : res);

          handleErr(true);
        } else {
          console.log("success");
          setTimeout(async () => {
            setUpdated(updated + 1);
            getContractInfo(1);
            setShowRebateModal(false);
            setProcessing(0);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        setProcessing(false);
        set_err_msg("Rewards claimed must be more than 0");
        handleErr(true);
      }
    }
  };
  const approve = async ticketCount => {
    var ticketPrice = contractData.ticketPrice;

    if (ticketPrice * ticketCount > userCeto) {
      set_err_msg("You don't have enough CETO");
      handleErr(true);
    } else {
      if (allowance >= ticketPrice * ticketCount) {
        setApproved(true);
      } else {
        try {
          var res = await Ceto_contract_obj.approveFunc(
            ticketPrice * ticketCount,
            Contract_obj.contractAddress
          );
          setProcessing(2);
          console.log(res);
          if (res != "success") {
            setProcessing(false);
            set_err_msg(res.message ? res.message : res);
            handleErr(true);
          } else {
            setTimeout(async () => {
              setUpdated(updated + 1);
              setApproved(true);
              setProcessing(0);
            }, 5000);
          }
        } catch (error) {
          console.log(error);
          setProcessing(false);
          set_err_msg("Something went wrong!");
          handleErr(true);
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.bg_one}>
        <Navbar shownav={shownav} />

        <Section1
          buyTicket={buyTicket}
          approved={approved}
          approve={approve}
          allowance={allowance}
          processing={processing}
          contractData={contractData}
          currencies={currencies}
          Ceto_contract_obj={Ceto_contract_obj}
          timer={timer}
          live={live}
          withdrawRebate={withdrawRebate}
          rebate={rebate}
          userTickets={userTickets}
          setShowModal={setShowModal}
          setShowRebateModal={setShowRebateModal}
          cetoBalance={CETOBalance}
          updated={updated}
          node={node}
        />
      </div>
      {winnerData && <Winners contractData={winnerData}></Winners>}
      <Prizes></Prizes>
      <Rules></Rules>
      <Steps></Steps>
      <Partners></Partners>
      <FAQ />
      <Footer_></Footer_>
      {showModal && (
        <Modal
          open={true}
          setShowModal={setShowModal}
          buyTicket={buyTicket}
          approved={approved}
          approve={approve}
          allowance={allowance}
          processing={processing}
          userTickets={userTickets}
          contractData={contractData}
          node={node}
        />
      )}
      {showRebateModal && (
        <RebateModal
          open={true}
          setShowModal={setShowRebateModal}
          buyTicket={buyTicket}
          approved={approved}
          approve={approve}
          allowance={allowance}
          processing={processing}
          contractData={contractData}
          withdrawRebate={withdrawRebate}
          rebate={rebate}
          prob={prob}
          node={node}
        />
      )}
      {/* && userTickets > 1 */}
      <WaitingModal
        open={showWaitingModal && userTickets > 1}
        setOpen={setShowWaitingModal}
      ></WaitingModal>
      <LostModal
        open={showLoseModal && userTickets > 1}
        setOpen={setShowLoseModal}
      ></LostModal>
      <WinModal
        open={showWinModal}
        setOpen={setShowWinModal}
        position={prizeWon}
        contractData={winnerData}
      ></WinModal>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={err}
        autoHideDuration={6000}
        onClose={() => handleErr(false)}
      >
        <Alert onClose={() => handleErr(false)} severity="error">
          {err_msg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={success}
        autoHideDuration={6000}
        onClose={() => handleSuccess(false)}
      >
        <Alert onClose={() => handleSuccess(false)} severity="success">
          Transaction successful!
        </Alert>
      </Snackbar>
      <ConnectionModal
        setConnectModal={setConnectModal}
        openModal={connectModal}
        connection_issue={connection_issue}
        node={node}
      ></ConnectionModal>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    utils: state.utils,
  };
};

export default connect(mapStateToProps, actions)(Landing);
