import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { AppBar, Avatar, Box, Button, Menu, Toolbar } from "@material-ui/core";
import MainIcon from "../../assets/v2/logo.png";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import AmericanFlag from "../../assets/images/Icons/Flag.png";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";

const drawerWidth = 90;

const useStyles = makeStyles(theme => ({
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawerVisible: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  iconsVisible: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },

  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",

    flexGrow: 1,
    background: "#1A1A43",
    // height: "93px",
    margin: "0",
    padding: "0 10vw",
    position: "fixed",
    width: "100vw",
    zIndex: "2",
    [theme.breakpoints.up("xs")]: {
      margin: "0",
    },
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  color_red: {
    color: "#C90001",
  },

  row: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },

  container: {
    width: "100%",
    margin: "auto",
  },
  container2: {
    flex: 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      flex: 0,
    },
  },

  buttonFontSize: {
    letterSpacing: "3.2px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "33px",
    color: "#e0e0e0",
    marginRight: "5px",
    "&:focus": {
      textDecoration: "none",
      outline: "none",
      border: 0,
    },
    "&:hover": {
      textDecoration: "none",
      color: "white",
    },
  },

  AppBar: {
    background: "none",
    boxShadow: "none",
  },
  logo: {
    flex: 5,
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },

  mainLogo: {
    flex: 1,
    color: "#e0e0e0",
    justifyContent: "left",
    "&:hover": {
      background: "transparent",
    },
  },
  logoImage: {
    width: "61px",
  },
  hide: {
    display: "none",
  },
}));

export default function CenteredGrid({ shownav }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={`${shownav ? classes.root : classes.hide} `}>
      <AppBar position="static" color="primary" className={classes.AppBar}>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          className={classes.container}
        >
          <Toolbar>
            <div className={classes.logo}>
              <Button className={[classes.mainLogo, classes.buttonFontSize]}>
                <img src={MainIcon} className={classes.logoImage}></img>
              </Button>
            </div>

            <div className={classes.container2}>
              <Button
                color="inherit"
                className={[classes.iconsVisible, classes.buttonFontSize]}
                href="#tutorial"
              >
                ABOUT
              </Button>
              <Button
                color="inherit"
                className={[classes.iconsVisible, classes.buttonFontSize]}
                href="#faq"
              >
                FAQ
              </Button>
              <Button
                color="inherit"
                className={[classes.iconsVisible, classes.buttonFontSize]}
                onClick={() => window.open("https://twitter.com/CEToken")}
              >
                <TwitterIcon />
              </Button>
              <Button
                color="inherit"
                className={[classes.iconsVisible, classes.buttonFontSize]}
                onClick={() => window.open("https://t.me/OfficialCETO")}
              >
                <TelegramIcon />
              </Button>
            </div>
          </Toolbar>
        </Grid>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button>
            <ListItemText primary="GAMES" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="FAQ" style={{ fontSize: "17px" }} />
          </ListItem>
          <ListItem button>
            <ListItemIcon></ListItemIcon>
          </ListItem>
          <ListItem>
            <TwitterIcon style={{ width: "17px" }} />
          </ListItem>
          <ListItem>
            <TelegramIcon />
          </ListItem>
          <ListItem>
            <MailOutlineIcon />
          </ListItem>
          <ListItem>
            <img src={AmericanFlag} />

            <ArrowDropDownIcon />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}
