import styled from "styled-components";
export const Card = styled.div`
  .modal-content,
  .modal-card {
    background: #272750;
    border-radius: 7px;
@media screen and (max-width: 600px) {
    background: #272750;
    }
  }
  .modal-content {
    position: relative;
    padding: 30px 40px 40px 60px;
    width: 40vw;
    font-family: "Poppins", sans-serif;

    @media screen and (max-width: 600px) {
      width: 90vw;
      padding: 30px 1rem;
    }

    p {
      color: #162752;
      color: #162752c2;
      font-weight: 500;
      font-size: 18px;

      a {
        color: #FF5C34;
      }
    }
    .upperdiv {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .custom {
        color: #691aaa;
        font-size: 32px;
        font-weight: 300;
      }
    }

    .ref-div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-right: 20px;
      margin-bottom: 90px;
      flex-wrap: wrap;
      .domain {
        color: #7689b0;
        font-size: 1rem;
        font-weight: 500;
        margin-top: 30px;
        margin-right: 17px;
        @media screen and (max-width: 600px) {
          margin-top: 0;
          margin-bottom: 1rem;
        }
      }

      .slug {
        display: flex;
        flex-direction: column;
        flex: 1;
        .MuiOutlinedInput-root {
          font-family: "Poppins", sans-serif;
        }

        .slug-check {
          display: flex;
          align-items: center;
          min-width: 130px;
          .is-available {
            color: #7689b0;
            opacity: 1;
            font-size: 16px;
            font-weight: 500;
          }
        }
        .MuiInputBase-input {
          color: #002e8c !important;
          font-size: 24px !important;
          font-weight: 500 !important;
          padding: 20px 40px;
        }
        .cl {
          color: #002e8c;
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 12px;
        }
      }
    }
    .cl {
      color: #002e8c;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 12px;
    }
    .action-div {
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width: 600px) {
        justify-content: center;
      }
    }

    .middiv {
      display: flex;
      flex-direction: column;
      align-items: center;

      .warn {
        color: #FF5C34;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        margin: 30px 0 15px;
@media screen and (max-width: 600px) {
font-size: 16px;
      }

      }

      .detail {
        color: #162752;
        font-weight: 500;
      }
      ul {
        text-align: center;
      }
      .inf {
        color: #C2DEFF;
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        width: 65%;
        margin: auto;
        margin-bottom: 18px;
        @media screen and (max-width: 600px) {
        font-size: 15px;
        width: 100%;
        }
      }

      .bottomDiv {
      display: flex;
      flex-direction: column;
      align-items: center;
    .tronlink {
       margin: 30px;
        width: 50px;
        align-items: center;
      }
      .bottom {
         color: white;
         font-weight: 400;
        @media screen and (max-width: 600px) {
        padding: 0 50px;
         font-size: 14px;
          }
      }

            .inff {
        color: #162752;
        font-size: 15px;
        /* font-weight: 500; */
        text-align: center;
        width: 65%;
        margin: auto;
        margin-bottom: 1em;
      }
      
      p {
        text-align: center;
        color: #1a445b;
      }
    }
  }
  .modal-close {
    position: absolute;
  }
  .l {
    @media screen and (max-width: 600px) {
      display: none !important;
    }
  }
  .s {
    @media screen and (min-width: 600px) {
      display: none !important;
    }
    .warn {
      color: #bc2a2b !important;
    }
    .inf {
      color: #162752c2 !important;
      font-size: 16px !important;

      margin-bottom: 15px !important;
    }
    a {
      color: #bc2a2b;
      font-weight: 500;
    }
    .txt1 {
      color: #162752;
      font-weight: 500;
      font-size: 17px;
      text-align: center;
      margin-bottom: 4px;
    }
  }
`;
