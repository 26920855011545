import React, { useEffect, useState } from "react";
import MainIcon from "../../assets/v2/logo.png";

import { TextField } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Card } from "./style";
import exchange from "../../public/Exchange - Icon.svg";
import arrow from "../../public/Button - Icon.svg";
import arrow2 from "../../public/Button - Icon (2).svg";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import FormHelperText from "@material-ui/core/FormHelperText";
import tronlink from "../../public/Tronlink.png";
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#009EE4",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #009EE4",
        borderRadius: "80px",
      },
      "&:hover fieldset": {
        borderColor: "#009EE4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#009EE4",
      },
    },
  },
})(TextField);
const ConnectionModal = ({
  openModal,
  connection_issue,
  setConnectModal,
  node,
}) => {
  const [bal, setbal] = useState(true);
  const [slug, setslug] = useState("");
  const [isavailable, setavail] = useState(true);

  const handleChange = e => {
    const { name, value } = e.target;
    setslug(value);
  };
  useEffect(() => {
    console.log(node, "jjjj");
  }, [node]);
  return (
    <Card>
      <div className={`modal ${openModal ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="upperdiv">
            <CloseIcon
              onClick={() => setConnectModal(false)}
              style={{ cursor: "pointer", color: "white" }}
            />
          </div>

          <div className="middiv">
            <img src={MainIcon} style={{ width: "100px" }}></img>
            <p className="warn">Welcome to the Show! </p>
            <p className="inf">
              {connection_issue == 0
                ? "Please connect your Tronlink or other compatible web wallet."
                : connection_issue == 1
                ? "Contract is not deployed on this network. Please change your network to "
                : " The next Big Tent event starts on "}
              <br></br>
              {connection_issue == 1 && (
                <span style={{ color: "#FF5C34", textTransform: "capitalize" }}>
                  {node == "private" ? "Nile" : "Mainnet"}.
                </span>
              )}
              {connection_issue == 2 && (
                <span style={{ color: "#FF5C34" }}>
                  {" "}
                  {new Date(parseInt(1621389600) * 1000)
                    .toDateString()
                    .slice(0, 10)}
                  {", "}
                  {new Date(parseInt(1621389600 * 1000))
                    .toLocaleString()
                    .split(", ")[1]
                    .slice(0, 4)}{" "}
                  {new Date(parseInt(1621389600 * 1000))
                    .toLocaleString()
                    .split(", ")[1]
                    .slice(-2)}
                </span>
              )}
              <br></br>
            </p>

            {connection_issue == 0 && (
              <div className="bottomDiv">
                <img src={tronlink} className="tronlink"></img>

                <p className="bottom">
                  Haven't installed TronLink yet?
                  <a
                    href="https://www.tronlink.org/"
                    target="_blank"
                  >{` Click here`}</a>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ConnectionModal;
