import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { makeStyles, Zoom } from "@material-ui/core";
import { Style } from "./modal_.js";
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Zoom
      in={true}
      ref={ref}
      {...props}
      style={{ transitionDelay: true ? "500ms" : "0ms" }}
    ></Zoom>
  );
});

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
  },
  paper: {
    borderRadius: "72px",
    padding: "50%",
  },
}));
export default function LostModal({ open, setOpen }) {
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Style className="container2">
      <Dialog
        open={open}
        className={classes.root}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ borderRadius: "29px" }}
        paper={{ borderRadius: "50%" }}
      >
        <div className={classes.root1}>
          <img
            src="https://i.giphy.com/media/hTHhAR2tyEGO6ifde0/giphy.webp"
            style={{ width: "100%", height: "100%" }}
          ></img>
        </div>
      </Dialog>
    </Style>
  );
}
