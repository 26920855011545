import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
/////images
import logo from "../../public/bell.png";
import gold from "../../assets/v2/gold_.png";
import split from "../../assets/v2/split.png";
import ticket from "../../assets/v2/ticket.png";
import timer from "../../assets/v2/timer.png";
import img51 from "../../assets/v2/img51.png";

import bg from "../../public/BGS/rules.png";
import "./style.css";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: "6vh 8vw",
    textAlign: "center",
    color: "white",
    backgroundSize: "cover",

    [theme.breakpoints.down("xs")]: {
      background: "#000F45",
    },
  },
  top_: {
    display: "flex",
    // justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingLeft: "20px",

      display: "flex",
      // justifyContent: "center",
    },
  },
  video1: {
    textAlign: "center",
    width: "83vw",
    height: "42vw",
    padding: "3rem",
    marginBottom: "2rem",
    // margin: "1rem auto 1rem auto",
    "@media (max-width: 960px)": {
      textAlign: "center",
      padding: "2rem",
      marginBottom: "3rem",
    },
    "@media (max-width: 600px)": {
      width: "95vw",
      height: "60vw",
      padding: "0",
      marginBottom: "3rem",
    },
  },
  iframe: {
    borderRadius: "20px",
    height: "90%",
    width: "65%",
    marginTop: "auto",
    "@media (max-width: 1024px)": {
      width: "80%",
      borderRadius: "15px",
      height: "100%",
    },
    "@media (max-width: 600px)": {
      width: "80%",
      borderRadius: "10px",
      height: "90%",
      marginRight: "3rem",
    },
    "@media (max-width: 400px)": {
      width: "80%",
      borderRadius: "10px",
      height: "90%",
      marginRight: "2rem",
    },
    // @media (max-width: 1024px) {
    //   width: 90%,
    //   height: 60vw,
    //   font-size: 11px,
    //   line-height: 1.3,
    //   .ytp-impression-link-text {
    //     font-size: 12px,
    //   }
    // }
  },
  red: {
    background: "#C90001",
    borderRadius: "50%",
    padding: "1em",
    width: "100px",
    height: "100px",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      width: "50px",
      height: "50px",
    },
  },
  logo_: {
    margin: "auto",
  },
  txt1: {
    fontWeight: "400",
    fontSize: "34px",

    textAlign: "left",
    margin: "auto 0 10px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "1.4em",
      fontWeight: "400",
      textAlign: "center",
    },
  },
  break: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  ol_: {
    listStyle: "initial",
  },
  li_: {
    color: "#C7EDFA",
    lineHeight: "2.3em",
    textAlign: "left",
    fontWeight: "300",
    fontSize: "1.2em",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
      fontWeight: "300",
      lineHeight: "1.5em",
    },
  },

  section1: {
    margin: "8vh auto",

    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
    },
  },
  bolder: {
    fontWeight: "300",
    fontSize: "16px",
  },
  inner_: {
    marginLeft: "20px",
    listStyle: "disc",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      marginLeft: "40px",
    },
  },
  txt3: {
    color: "white",
    margin: "2vh 0",
    marginBottom: "12vh",
    fontSize: "15px",
    [theme.breakpoints.down("sm")]: {
      color: "#FFF",
      fontWeight: 300,
    },
  },
  head: {
    fontSize: "34px",
    paddingBottom: "1vh",
    width: "fit-content",
    margin: "5vh 0",
    fontWeight: "400",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      textAlign: "center",
      fontSize: "1.5em",
      marginBottom: "80px",
    },
  },
  flex_box: {
    display: "flex",
    flexWrap: "wrap",
    padding: "5vh 5vw 2vh",
    background: "#1A1A43",
    position: "relative",
    zIndex: "1",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  txt_4: {
    // width: "50%",
    fontSize: "19px",
    fontWeight: "300",
    marginBottom: "1em",
    textAlign: "left",
    color: "#CFF4FF",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
      width: "100%",
      margin: "1vw auto",
    },
  },

  bold: {
    fontWeight: 500,
    color: "white",
  },

  blue_txt: {
    color: "#519AB4",
    [theme.breakpoints.down("sm")]: {
      display: "inline",
    },
  },
  box__1: {
    width: "49%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  line: {
    // borderBottom: "1px solid red",
    marginBottom: "1em",

    width: "100px",
    opacity: "0.3",
    background: "linear-gradient(to right, #ffffff36,#63C7FF,#ffffff36)",
    height: "0.8px",
  },
  imgRight: {
    position: "absolute",
    right: "0",
    top: "-107px",
    [theme.breakpoints.down("sm")]: {
      transform: "scale(0.5)",
      top: "-80px",
    },
  },
  flex_1: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "8vh",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  rule: {
    display: "flex",
    flexDirection: "column",
    width: "20%",
    textAlign: "left",
    // margin: "1vw 3vw",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "center",
      marginTop: "4em",
    },
  },
  ruleDiv: {
    position: "relative",
    height: "80px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  ruleImage: {
    position: "absolute",
    bottom: "0%",
  },
  txt_11: {
    marginTop: "1em",
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "36px",
  },
  txt_12: {
    marginTop: "1.2em",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "26px",
  },
  styled: {
    fontWeight: 600,
    fontStyle: "italic",
  },
  italics: {
    fontStyle: "italic",
    fontWeight: 500,
    lineHeight: "23px",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
    },
  },
}));

export default function Rules() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <div className={classes.top_}>
          <div className={classes.txt1} id="tutorial">
            What Is <span className={classes.blue_txt}>Big Tent?</span>
            {/* <br className={classes.break} /> */}
          </div>
        </div>
        <div className={classes.video1}>
          <iframe
            className={classes.iframe}
            src="https://www.youtube.com/embed/g5b6voiuDRA"
            // frameborder="0"
            title="tutorialMain"
            // allowfullscreen="true"
          ></iframe>
        </div>
        <div className={classes.top_}>
          <div className={classes.txt1}>
            Step into
            <span className={classes.blue_txt}> the Ring</span>
          </div>
        </div>
        <div className={classes.section1}>
          <div className={classes.flex_1}>
            <div className={classes.rule}>
              <div className={classes.ruleDiv}>
                <img src={ticket} className={classes.ruleImage}></img>
              </div>
              <div className={classes.txt_11}>
                {" "}
                Big Tent is a rolling raffle where the excess funds from a
                guaranteed prize goal (GPG) helps fund the next one - as well as
                a promotional partner.
                <span className={classes.styled}>
                  {" "}
                  Guaranteed Prize Goal (GPG)
                </span>{" "}
                helps fund the next one - as well as a promotional partner
              </div>
            </div>

            <div className={classes.rule}>
              <div className={classes.ruleDiv}>
                <img src={timer} className={classes.ruleImage}></img>
              </div>
              <div className={classes.txt_11}>
                All Big Tent events have a start date goal and GPG. Once the GPG
                is reached, the drawing takes place 24 hours later.
              </div>
            </div>
            <div className={classes.rule}>
              <div className={classes.ruleDiv}>
                <img src={split} className={classes.ruleImage}></img>
              </div>
              <div className={classes.txt_12}>
                {" "}
                All entry fees over the GPG are split:
                <br />
                <br />
                <ul className={classes.inner_}>
                  <li className={classes.bolder}>
                    50% to the promotional partner
                  </li>
                  <li className={classes.bolder}>
                    25% back into the prize pool
                  </li>
                  <li className={classes.bolder}>
                    25% toward seeding the next Big Tent event
                  </li>
                </ul>
              </div>
            </div>
            <div className={classes.rule}>
              <div className={classes.ruleDiv}>
                <img src={img51} className={classes.ruleImage}></img>
              </div>
              <div className={classes.txt_11}>
                <div>
                  Those entries that do not win one of the top prizes will split
                  proportionally the dividends earned by the Big Tent event CETO
                  wallet.
                </div>
                <br />
                <div className={classes.italics}>
                  Even when you don’t win, you can’t lose!
                </div>
              </div>
            </div>
          </div>

          <div className={classes.txt3}>
            ALL TRANSACTIONS FOR BIG TENT ARE SUBJECT TO CETO’S STANDARD 10%
            TRANSACTION FEE
          </div>
        </div>
        <div className={classes.section2}>
          <div className={classes.head}>
            <span className={classes.blue_txt}> Benefits from a </span>
            <br></br>Big Tent event?
          </div>
          <div className={classes.flex_box}>
            <img src={gold} className={classes.imgRight}></img>

            <div className={classes.box__1}>
              <div className={classes.txt_4}>
                Play big <span className={classes.bold}>Games</span>, win{" "}
                <span className={classes.bold}>BIG PRIZES</span>
              </div>
              <div className={classes.line}></div>
              <div className={classes.txt_4}>
                The only <span className={classes.bold}>Raffle</span> guaranteed
                to give <span className={classes.bold}>Money Back</span>
              </div>
            </div>
            <div className={classes.box__1}>
              <div className={classes.txt_4}>
                More <span className={classes.bold}>Transactions</span> = More{" "}
                <span className={classes.bold}>Dividends</span> for all
              </div>
              <div className={classes.line}></div>

              <div className={classes.txt_4}>
                Use your <span className={classes.bold}>CETO</span> to support
                great promotional partners
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
