import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { DialogContent, makeStyles, Zoom } from "@material-ui/core";
import "./win_modal.scss";
import Lottie from "react-lottie";
import animationData from "../../assets/win_lottie.json";
import confettiAnimationData from "../../assets/confetti.json";
import win from "../../assets/v2/win.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Zoom
      in={true}
      ref={ref}
      {...props}
      style={{ transitionDelay: true ? "500ms" : "0ms" }}
    ></Zoom>
  );
});

const useStyles = makeStyles(theme => ({
  root: {
    backgroundSize: "cover",
    borderRadius: "10px",
    flexDirection: "row",

    justifyContent: "center",
  },
  outer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },

  image: {
    width: "180px",
    height: "230px",
    margin: "50px 80px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  imageMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
      display: "block",
      width: "80px",
      height: "105px",
    },
  },
  congrats: {
    marginTop: "20px",
    fontFamily: "Poppins",
    fontWeight: "500",
    fontStyle: "italic",
    fontSize: "1.4em",
    textAlign: "center",

    color: "white",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
    },
  },
  txt1: {
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "2.2em",
    textAlign: "center",

    color: "#00386C",
  },
  txt2: {
    fontFamily: "Poppins",
    fontWeight: "300",
    fontSize: "2em",
    lineHeight: "61px",
    textAlign: "center",

    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5em",
      lineHeight: "40px",
    },
  },
  txt3: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "1.6em",
    textAlign: "center",

    color: "white",
  },
  right: {
    paddingTop: "80px",
    height: "100%",
    width: "250px",
    marginBottom: "2em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "20px",
      alignItems: "center",
    },
  },
}));
export default function WinModal({ open, setOpen, contractData, position }) {
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions_ = {
    loop: true,
    autoplay: true,
    animationData: confettiAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="container1">
      <Dialog
        open={open}
        className={classes.root}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Lottie
          options={defaultOptions_}
          style={{ width: "100%", position: "absolute" }}
        />
        <div className={classes.outer}>
          <div className={classes.left}>
            <img src={win} className={classes.image} />
          </div>

          <div className={classes.right}>
            <div className={classes.txt2}>
              {position == 1 ? "1st" : position == 2 ? "2nd" : "3rd"} Prize
            </div>
            <div className={classes.txt3}>
              {contractData &&
                (
                  parseInt(
                    contractData.result[
                      position == 1
                        ? "firstPrize"
                        : position == 2
                        ? "secondPrize"
                        : "thirdPrize"
                    ]
                  ) / 1e6
                ).toFixed(2)}{" "}
              CETO
            </div>
            <div>
              <img src={win} className={classes.imageMobile} />
            </div>

            <div className={classes.congrats}>Congratulations !!!</div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
