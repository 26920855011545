import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Snackbar,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import axiosInstance from "../../config/axios";

/////images
import logo from "../../public/partner_logo.png";
import bg from "../../public/BGS/partners.png";
import partner_1 from "../../public/partner_1.png";
import fb from "../../public/fb.png";
import twitter from "../../public/twitter.png";
import gmail from "../../public/G-Mail.png";
import telegram from "../../public/Telegram.png";
import share from "../../assets/v2/share_yellow.png";
import ceto from "../../assets/v2/ceto.png";
import eceto from "../../assets/v2/eceto.png";
import bceto from "../../assets/v2/bceto.png";
import yellow_arrow from "../../assets/v2/yellow_arrow.png";
import cetoFoundataion from "../../assets/v2/cetoFoundation.png";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    background: "transparent",
    padding: "6vh 0",
    textAlign: "center",
    color: "white",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      padding: "30px",
    },
  },
  top_: {
    display: "flex",
    justifyContent: "center",
  },
  second: {
    margin: "90px 0 72px",
    [theme.breakpoints.down("sm")]: {
      margin: "80px 0 -40px",
    },
  },
  red: {
    borderRadius: "50%",
    padding: "1em",
    width: "100px",
    height: "100px",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      width: "60px",
      height: "60px",
    },
  },
  logo_: {
    margin: "auto",
  },
  txt1: {
    fontWeight: "400",
    fontSize: "2em",
    margin: "auto 1em auto 0",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5em",
    },
  },
  head_txt1: {
    fontWeight: "300",
    fontSize: "23px",
    margin: "auto  0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2em",
    },
  },
  cetoFoundataion: {
    display: "inline-block",
    margin: 0,
    width: "50px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hidden: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
    },
  },
  txt2: {
    fontWeight: "300",
    fontSize: "19px",
    // marginTop: "1em",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
    },
  },
  partners: {
    display: "flex",
    justifyContent: "center",
    padding: "1vw 2vw 2vw",
    flexWrap: "wrap",
    width: "100vw",
    [theme.breakpoints.down("xs")]: {
      width: "fit-content",
    },
  },
  partner_logo: {
    margin: "2em 5em",
    width: "8vw",
    [theme.breakpoints.down("xs")]: {
      margin: "1em 2em",
      width: "50px",
    },
  },
  flex_: {
    display: "flex",
    justifyContent: "space-between",
    width: "27%",
    margin: "2.7em auto",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "25px",
      width: "62%",
    },
  },
  circle: {
    background: "white",
    borderRadius: "50%",
    padding: "0.8em",
    marginTop: "1em",
    width: "55px",
    height: "55px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0.6em",
      margin: "0.3em",
      width: "42px",
      height: "42px",
    },
  },
  social: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  fb: {
    width: "50%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  box_1: {
    // height: "120px",
    marginBottom: "7vh",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      flexDirection: "column",
    },
  },
  left_box: {
    height: "100px",
    display: "flex",
    alignItems: "flex-end",
    margin: "0 auto",
  },
  right_box: {
    height: "100px",
    display: "flex",
    alignItems: "flex-end",
    margin: "1em auto",
  },

  discord_btn: {
    background: "#EF8D1A",
    padding: "20px 30px",
    borderRadius: "50px",
    textTransform: "initial",
    fontWeight: "500",
    fontSize: "18px",
    fontFamily: "Poppins",
    height: "55px",
    width: "fit-content",
    down: "0%",
    "&:hover": {
      background: "#cc7b1b",
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: "50px",
      width: "190px",
      fontSize: "13px",
      height: "41px",
      flexDirection: "column",
      margin: "2em auto",
    },
  },
  form: {
    borderRadius: "50px",
    height: "55px",
    background: "#1A2759",
    width: "fit-content",
    padding: "0 1em",
    margin: "auto",
  },
  input: {
    color: "white",
    fontSize: "15px",
    margin: "0.8em 0",
    fontFamily: "Poppins",
    fontWeight: "300",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
    },
  },
  txt3: {
    fontSize: "12px",
    fontWeight: "300",
    marginBottom: "15px",
  },
}));

export default function Partners() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [processing, setProcessing] = useState(false);
  const [success, handleSuccess] = useState(false);

  const [positions, setPositions] = React.useState({
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal } = positions;
  const subscribe = e => {
    console.log("subscribe");
    // setProcessing(true);
    e && e.preventDefault();
    axiosInstance
      .post("/api/subscribe/", { email: email })
      .then(res => {
        setProcessing(false);
        setEmail("");
        handleSuccess(true);
      })
      .catch(error => {
        console.log("error", error);
        setProcessing(false);
      });
  };
  return (
    <>
      <div className={classes.root}>
        <div className={classes.top_}>
          <div className={classes.head_txt1}>
            Brought to you by{" "}
            <img src={cetoFoundataion} className={classes.cetoFoundataion} />{" "}
            <span className={classes.hidden}>the team</span> of CETO blockchain
            family
          </div>
        </div>
        <div className={classes.partners}>
          <img src={ceto} className={classes.partner_logo}></img>
          <img src={eceto} className={classes.partner_logo}></img>
          <img src={bceto} className={classes.partner_logo}></img>
        </div>
        <div className={classes.box_1}>
          <div className={classes.left_box}>
            <Button
              variant="contained"
              size="large"
              className={classes.discord_btn}
              onClick={() => window.open("https://discord.gg/Jxg8GzZ3kq")}
            >
              Discuss on Discord
            </Button>
          </div>
          <div className={classes.right_box}>
            <div>
              <div className={classes.txt3}>
                Sign up for email updates from the CETO Foundation
              </div>
              <form
                component="form"
                className={classes.form}
                onSubmit={e => subscribe(e)}
              >
                <InputBase
                  required
                  type="email"
                  className={classes.input}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Enter email address"
                  inputProps={{ "aria-label": "Enter email address" }}
                />
                {processing ? (
                  <CircularProgress
                    style={{
                      width: "30px",
                      height: "30px",
                      color: "#ef8d1a",
                      margin: " 0.8em 0",
                    }}
                    // className={classes.iconButton}
                  />
                ) : (
                  <IconButton
                    type="submit"
                    color="primary"
                    className={classes.iconButton}
                    aria-label="directions"
                  >
                    <img src={yellow_arrow}></img>
                  </IconButton>
                )}
              </form>
            </div>
          </div>
        </div>
        <div className={classes.second}>
          <div className={classes.top_}>
            <div className={classes.red}>
              <img src={share} className={classes.logo_}></img>
            </div>
            <div className={classes.txt1}>Share the Show</div>
          </div>
          <div className={classes.txt2}>
            Share this Big Tent event with friends
          </div>
          <div className={classes.flex_}>
            <div
              className={classes.circle}
              onClick={() => window.open("https://twitter.com/CEToken")}
            >
              <img src={twitter} className={classes.social}></img>
            </div>
            <a
              className={classes.circle}
              href="mailto:bigtent@crystalelephant.net"
            >
              <img src={gmail} className={classes.social}></img>
            </a>
            <div
              className={classes.circle}
              onClick={() => window.open("https://t.me/OfficialCETO")}
            >
              <img src={telegram} className={classes.social}></img>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={success}
        autoHideDuration={6000}
        onClose={() => handleSuccess(false)}
      >
        <Alert onClose={() => handleSuccess(false)} severity="success">
          Subscribed successfully!
        </Alert>
      </Snackbar>
    </>
  );
}
