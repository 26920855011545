import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Modal from "./Modal";
import { withStyles } from "@material-ui/core/styles";
import { BiChevronDown } from "react-icons/bi";
import React, { useState, useReducer, useEffect } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ConnectionModal from "../connectionPopup";
import metacoinConfigCeto from "../../metacoin-config-ceto";
import axios from "axios";
import logo from "../../assets/images/Icons/logo.png";
import chest1 from "../../assets/v2/chests1.png";
import chest2 from "../../assets/v2/chests2.png";
import rays from "../../assets/v2/Rays.png";
import i from "../../assets/v2/i.png";
import coins from "../../assets/v2/coins.png";
import timer2 from "../../assets/v2/timer2.png";
import ceto from "../../assets/v2/ceto.png";
import eceto from "../../assets/v2/eceto.png";
import bceto from "../../assets/v2/bceto.png";
import cup from "../../assets/v2/cup.png";
import stem1 from "../../assets/v2/stem1.png";
import stem2 from "../../assets/v2/stem2.png";
import "./style.css";
import metacoinConfig from "../../metacoin-config";
import RebateModal from "./RebateModal";
import dropdown from "../../assets/v2/dropdown.png";
import CetoFoundation from "../../assets/v2/cetoFound.png";
import Ticket from "../../assets/v2/raffle.png";
import { Tooltip } from "antd";
import "antd/dist/antd.css";
import Axios from "axios";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    background: "transparent",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
    },
  },

  container: {
    margin: "auto",
  },
  base_font: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    align: "center",
  },
  line1: {
    fontSize: "35px",
    lineHeight: "56.32px",
    color: "#FFFFFF",
    fontWeight: 300,
    [theme.breakpoints.down("xs")]: {
      lineHeight: "30.32px",
      fontSize: "30px",
    },
  },
  line2: {
    fontSize: "55px",
    lineHeight: "88.32px",
    fontWeight: 700,
    color: "#C90001",
    [theme.breakpoints.down("xs")]: {
      fontSize: "40px",
      lineHeight: "48.32px",
    },
  },

  line3: {
    fontWeight: 300,
    fontSize: "20px",
    color: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },

  line4: {
    fontWeight: 400,
    fontSize: "35px",
    color: "#C7EDFA",
    lineHeight: "63px",
    marginTop: "30.5px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10.5px",
      fontSize: "22px",
      lineHeight: "30px",
    },
  },

  buy_btn: {
    background: "linear-gradient(90deg, #E33031 33.1%, #E35B30 100%)",
    width: "249px",
    height: "58px",
    color: "#fff",
    borderRadius: "100px",
    padding: "0.8rem 2.2rem",
    fontFamily: "Poppins",
    fontSize: "17px",
    fontWeight: "600",
    fontStyle: "normal",
    letterSpacing: "0.255em",
    "&:hover": {
      background: "linear-gradient(90deg, #d02b2c 33.1%, #c34f2a 100%)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "200px",
      height: "60px",
      padding: ".2rem",
      fontSize: "16px",
      fontWeight: 700,
    },
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
    },
  },

  line5: {
    marginTop: "15px",
    fontFamily: "Poppins",
    fontWeight: "normal",
    fontSize: "25px",
    color: "#FFFFFF",
    lineHeight: "29.3px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10.5px",
      fontSize: "18px",
      lineHeight: "15px",
    },
  },

  line6: {
    fontSize: "23px",
    lineHeight: "34px",
    color: "#FFFFFF",
    marginTop: "40px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10.5px",
      fontSize: "16px",
      lineHeight: "15px",
    },
  },
  line7: {
    fontWeight: 400,
    fontSize: "38px",
    color: "#C7EDFA",
    lineHeight: "42.56px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10.5px",
      fontSize: "18px",
      lineHeight: "20px",
    },
  },
  hr1: {
    width: "304px",
    margin: "50px",
    backgroundColor: "#63F5FF",
    display: "block",
    height: "2px",
    position: "relative",
    verticalAlign: "middle",
    [theme.breakpoints.down("xs")]: {
      margin: "10px",
      width: "200px",
      height: "2px",
    },
  },

  line8: {
    fontWeight: 500,
    fontSize: "38px",
    color: "#C7EDFA",
    lineHeight: "70px",
    [theme.breakpoints.down("xs")]: {
      fontWeight: 500,
      marginTop: "10.5px",
      fontSize: "23px",
      lineHeight: "35px",
    },
  },

  line9: {
    fontWeight: 300,
    fontSize: "35px",
    lineHeight: "54px",
    letterSpacing: "0.1em",
    color: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      fontWeight: 500,
      fontSize: "25px",
      lineHeight: "32px",
    },
  },
  hr2: {
    backgroundColor: "#63F5FF",
    display: "inline-block",
    height: "2px",
    position: "relative",
    verticalAlign: "middle",
    width: "10%;",
    marginLeft: "30px",
    marginRight: "30px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "10px",
      marginRight: "10px",
    },
  },
  winners: {
    marginTop: "40px",
    width: "1000px",
    margin: "auto",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",

    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },

  runnerUpPos: {
    fontWeight: 400,
    fontSize: "19px",
    lineHeight: "28px",
    color: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
      lineHeight: "16px",
    },
  },

  runnerUpAmount: {
    fontWeight: 400,
    fontSize: "22px",
    lineHeight: "28px",
    color: "#C7EDFA",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "16px",
      margin: "0 0",
      width: "120px",
    },
  },
  firstPos: {
    fontWeight: 400,
    fontSize: "28.3px",
    lineHeight: "26.48px",
    color: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: "20px",
    },
  },

  firstPosAmount: {
    fontWeight: 500,
    fontSize: "32.3px",
    lineHeight: "48.5px",
    color: "#C7EDFA",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: "20px",
    },
  },

  logo: {
    width: "140px",
  },
  txt_11: {
    fontSize: "23px",
    fontWeight: "400",
    lineHeight: "27px",
    color: "white",
    textAlign: "center",
    margin: "1em 0",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },

  txt_111: {
    fontWeight: 300,
  },
  bold: {
    fontSize: "24px",
    fontWeight: 500,
  },
  bold2: {
    fontSize: "23px",
    fontWeight: 500,
  },
  ticket: {
    marginLeft: "5px",
  },
  prizeGoal: {
    margin: "40px 0",
  },
  txt13: {
    color: "white",
    fontSize: "19px",
    fontWeight: 500,
    lineHeight: "30px",
  },
  txtAmount: {
    color: "#FF7629",
    fontSize: "24.5px",
    fontWeight: 500,
    lineHeight: "40px",
  },
  txtAmount1: {
    color: "white",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "40px",
  },
  txt12: {
    color: "white",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      fontSize: "20px",
    },
  },

  mid: {
    backgroundImage: `url(${rays})`,
    position: "relative",
    paddingBottom: "2vh",
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      marginTop: "-10px",
      overflow: "hidden",
    },
  },
  left_chest: {
    position: "absolute",
    left: "2vw",
    top: "2vw",
    [theme.breakpoints.down("sm")]: {
      /**
      opacity: 0.5,
      transform: "scale(0.6)",
      zIndex: -1,
      left: '-50%',
      top: '-20%',
      */
      display: "none",
    },
  },
  right_chest: {
    right: "2vw",
    top: "2vw",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      /**
      overflow: 'hidden',
      opacity: 0.5,
      transform: "scale(0.6)",
      zIndex: -1,
      right: '-40%',
      top: '-20%',
      */
      display: "none",
    },
  },
  txt22: {
    fontSize: "20px",
    fontWeight: "300",
    color: "white",
    marginTop: "1em",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  orange: {
    fontSize: "19.25px",
    fontWeight: "500",
    color: "#FF7629",
  },
  line: {
    height: "9vh",
    margin: "auto 3em",
    width: "1px",
    background: "linear-gradient(to bottom, #ffffff00,#63c7ff80,#ffffff00)",
    [theme.breakpoints.down("sm")]: {
      transform: "rotate(0.25turn)",
    },
  },
  box__11: {
    display: "flex",
    background: "#1A1A43",
    borderRadius: "88px",
    padding: "33px 1em",
    justifyContent: "space-evenly",
    margin: "0 3vw",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      borderRadius: "10px",
      width: "80%",
      margin: "0 auto",
      position: "relative",
    },
  },
  part1: {
    display: "flex",
  },
  right_: {
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
  },
  part3: {
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
  },
  part2: {
    display: "flex",
  },
  coins: {
    width: "63px",
    height: "72px",
    margin: "auto",
    marginRight: "1em",
  },
  timer2: {
    margin: "auto",

    width: "63px",
    marginRight: "1em",
    [theme.breakpoints.down("sm")]: {
      height: "60px",
    },
  },
  txt111: {
    fontWeight: "500",
    fontSize: "15px",
    color: "#F99742",
  },
  txt112: {
    fontWeight: "500",
    fontSize: "28px",
    color: "#F99742",
  },
  small: {
    fontSize: "22px",
    textTransform: "uppercase",
  },
  currency: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "25px",
    color: "white",
    marginRight: "10px",
  },
  dropdown: {
    width: "11px",
  },
  txt114: {
    fontWeight: "500",
    fontSize: "15px",
    color: "white",
  },
  txt115: {
    fontWeight: "500",
    fontSize: "23px",
    color: "white",
    display: "flex",
  },
  i: {
    padding: "0 10px",
  },
  txt116: {
    fontWeight: "300",
    fontSize: "16.5px",
    color: "white",
    letterSpacing: "0.11em",
    textAlign: "center",
    marginBottom: "0.5em",
  },
  bottom_div: {
    display: "flex",
    background: "#212150",
    borderRadius: "100px",

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  bottom_div_: {
    display: "flex",
    background: "#212150",
    borderRadius: "100px",
    flexDirection: "column",
    background: "#0f0f33",
    // border: "1px solid #E35B30",
    display: "flex",
    justifyContent: "space-between",
    margin: "3em 1em 1em",

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  txt117: {
    fontWeight: "300",
    fontSize: "15px",
    color: "white",
    letterSpacing: "0.075em",
    margin: "auto 22px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: "13px",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      fontSize: "13px",
      marginBottom: "1em",
    },
  },
  section1: {
    // height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  top_: {
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "27px",
    textAlign: "center",
    color: "white",
    margin: "2em 0 5em",

    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "45px",
      margin: "2em 0 4em",
    },
  },
  wallet: {
    float: "right",
    marginTop: "-35px",
    marginRight: "4em",
  },
  topImage: {
    width: "35px",
  },
  mobileBreak: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  ceto: {
    width: "50px",
    margin: "0 0.5em",
  },
  imageSpace: {
    display: "inline",
    marginLeft: "0.3em",
    width: "300px",
  },
  prizes: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    margin: "8em 0 3em",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "3em 0 3em",
    },
  },
  prize: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#1A1A43",
    margin: "1em",
    alignItems: "center",
    padding: "1em 4em 1em",
    [theme.breakpoints.down("sm")]: {
      margin: "6em 2em 0em 2em",
      padding: 0,
    },
  },
  cup: {
    width: "47px",
    height: "47px",
    margin: "1em auto",
  },
  amount: {
    fontWeight: "400",
    color: "white",
    margin: "1em 0",

    fontSize: "23px",
  },
  sub: {
    fontSize: "18px",
  },
  circle: {
    width: "83px",
    height: "83px",
    backgroundColor: "#253261",
    borderRadius: "50%",
    margin: "auto",
    marginTop: "-54px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontWeight: "500",
    color: "white",

    fontSize: "21px",
  },
  first: {
    transform: "scale(1.2)",
    [theme.breakpoints.down("sm")]: {
      transform: "scale(1)",
    },
  },
  rebate: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  break: {
    display: "none",

    [theme.breakpoints.down("sm")]: {
      display: "inline",
    },
  },
  r_txt: {
    color: "white",
    fontWeight: "300",
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
  r_txt2: {
    fontSize: "23px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
    },
  },
  r_btn: {
    color: "#FC9C2C",
    fontWeight: "300",
    fontSize: "20px",
    boxShadow: "none",
    textTransform: "initial",
    background: "transparent",
    padding: "0",
    "&:hover": {
      boxShadow: "none",
      background: "transparent",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileButton: {
    color: "#FC9C2C",
    fontWeight: "300",
    fontSize: "15px",
    boxShadow: "none",
    textTransform: "initial",
    background: "transparent",
    padding: "0",
    "&:hover": {
      boxShadow: "none",
      background: "transparent",
    },

    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
    },
  },
  margin: {
    margin: "6em",
  },
}));

const reducer = (state, action) => {};
const defaultState = {
  tickets: 1,
  price: 5.5,
  isModalOpen: true,
};

const StyledMenu = withStyles({
  paper: {
    backgroundColor: "rgba(43, 53, 92, 1)",
    height: "250px",
    "&::-webkit-scrollbar": {
      padding: "1px",
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 50px rgba(43, 53, 92, 1)",
      webkitBoxShadow: "inset 0 0 0 rgba(43, 53, 92, 1)",
      display: "none",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#5B6DB4",
      borderRadius: "5px",
    },
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    borderBottom: "1px solid rgba(72, 90, 158, 1)",
    // width: "120px",

    "&:not(:last-child)": {},

    backgroundColor: "rgba(43, 53, 92, 1)",
    color: "white",
    "&:focus": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "white",
      },
    },
  },
}))(MenuItem);

const ITEM_HEIGHT = 48;

export default function Section1({
  buyTicket,
  approved,
  approve,
  allowance,
  contractData,
  processing,
  currencies,
  timer,
  Ceto_contract_obj,
  withdrawRebate,
  rebate,
  userTickets,
  setShowModal,
  setShowRebateModal,
  cetoBalance,
  updated,
  node,
}) {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, defaultState);
  const [price, setPrice] = useState("$550");
  const [connectModal, setConnectModal] = useState(false);
  const [connection_issue, setConnectionIssue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tokens_to_crypto, set_tokens_to_crypto] = useState();
  const [prob, setProb] = useState();
  const [countdownTimer, setCountdownTimer] = useState();
  const [tronPrice, setTronPrice] = useState(85.79);
  const [wallet, setWallet] = useState();
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkConnection = async e => {
    if (
      !window.tronWeb ||
      (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
    ) {
      setConnectModal(true);
      setConnectionIssue(0);
    } else if (
      window.tronWeb &&
      window.tronWeb.fullNode.host != metacoinConfigCeto[node].fullHost
    ) {
      setConnectionIssue(1);
      setConnectModal(true);
    } else {
      if (e == 1) {
        setShowModal(true);
      } else {
        setShowRebateModal(true);
      }
    }
  };

  const getcurrentTimestamp = () => {
    var now = new Date();
    now = parseInt(now.getTime().toString().slice(0, -3));
    return now;
  };
  const getData = async () => {
    if (parseInt(cetoBalance) == 0) {
      set_tokens_to_crypto(0);
    } else {
      var config = {
        method: "get",
        url: `https://api.crystalelephant.net/api/cache/?function=tokensToTron_&arguments=${cetoBalance}&network=${metacoinConfigCeto[node].fullHost}&contract_address=${metacoinConfigCeto[node].contractAddressBase}&sender_address=${metacoinConfigCeto[node].secondaryAccount}`,
      };
      axios(config)
        .then(function (res) {
          set_tokens_to_crypto(parseInt(res.data.data.toString()));
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const cetoToTron = async val => {
    if (parseInt(val) == 0) {
      return 0;
    } else {
      var config = {
        method: "get",
        url: `https://api.crystalelephant.net/api/cache/?function=tokensToTron_&arguments=${val}&network=${metacoinConfigCeto[node].fullHost}&contract_address=${metacoinConfigCeto[node].contractAddressBase}&sender_address=${metacoinConfigCeto[node].secondaryAccount}`,
      };
      axios(config)
        .then(function (res) {
          return res.data.data.toString();
        })
        .catch(function (error) {
          console.log(error);
          return 0;
        });
    }
  };
  const truncate = num => {
    const number = num.toString();
    const digits = number.split(".");
    const decimal = digits[1].split("");
    const final = digits[0] + "." + (decimal[0] + "" + decimal[1]);
    return final;
  };

  useEffect(() => {
    contractData && getData();
    if (contractData) {
      var prob_ =
        (userTickets / contractData.participantsCount +
          ((contractData.participantsCount - userTickets) /
            contractData.participantsCount) *
            (userTickets / (contractData.participantsCount - 1)) +
          ((contractData.participantsCount - userTickets) /
            contractData.participantsCount) *
            ((contractData.participantsCount - userTickets - 1) /
              (contractData.participantsCount - 1)) *
            (userTickets / (contractData.participantsCount - 2))) *
        100;
      setProb(prob_);
      var config = {
        method: "get",
        url: `https://api.crystalelephant.net/api/buyprice/?cetoValue=${contractData.ticketPrice}&contract_address=${metacoinConfigCeto[node].contractAddressBase}`,
      };
      Axios(config)
        .then(function (res) {
          var price = res.data.data;
          price = price + price / 10;
          setTronPrice(price);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [Ceto_contract_obj, updated, contractData, currencies]);
  useEffect(() => {
    coundownData();
    window.addEventListener("load", async event => {
      var obj1 = setInterval(async () => {
        if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
          console.log("loadeded");

          setWallet(window.tronWeb.defaultAddress.base58);
        }
      }, 2000);
    });
  }, []);
  const coundownData = () => {
    const offset = 48600;
    const end_timestamp = 1621389600;

    var x = setInterval(function () {
      // Get today's date and time
      const currentDate = new Date();
      const now = parseInt(currentDate.getTime().toString().slice(0, -3));

      // Find the distance between now and the count down date
      var distance = end_timestamp - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (60 * 60 * 24));
      var hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
      var minutes = Math.floor((distance % (60 * 60)) / 60);
      var seconds = Math.floor(distance % 60);

      // Display the result in the element with id="demo"
      setCountdownTimer({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      });

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        setCountdownTimer({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      }
    }, 1000);
  };
  const getTimeString = () => {
    var date = new Date(parseInt(contractData.startDate * 1000));
    var hours = parseInt(date.getHours());
    var unit = hours > 12 ? "PM" : "AM";

    hours = hours > 12 ? hours - 12 : hours;
    hours = hours.toString().length < 2 ? "0" + hours.toString() : hours;

    // Minutes part from the timestamp
    var minutes =
      date.getMinutes().toString().length < 2
        ? "0" + date.getMinutes().toString()
        : date.getMinutes();
    // Seconds part from the timestamp
    var seconds = "0" + date.getSeconds();

    // Will display time in 10:30:23 format
    var formattedTime = hours + ":" + minutes + " " + unit;
    return formattedTime;
  };
  const deductFee = value => {
    var taxedVal = value - value / 10;
    return taxedVal;
  };
  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.section1}>
            <div className={classes.top_}>
              <div>
                From{" "}
                <img
                  src={CetoFoundation}
                  className={classes.topImage}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open("https://crystalelephant.net", "_blank")
                  }
                />{" "}
                of CETO Blockchain Family
                <br className={classes.mobileBreak} />
                <div className={classes.imageSpace}>
                  <img
                    src={ceto}
                    className={classes.ceto}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://crystalelephant.net/ceto/buy",
                        "_blank"
                      )
                    }
                  ></img>
                  <img
                    src={eceto}
                    className={classes.ceto}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://crystalelephant.net/eceto/buy",
                        "_blank"
                      )
                    }
                  ></img>
                  <img
                    src={bceto}
                    className={classes.ceto}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://crystalelephant.net/bceto/buy",
                        "_blank"
                      )
                    }
                  ></img>
                </div>
              </div>
              {/* <div className={classes.wallet}>
                <AccountBalanceWalletIcon></AccountBalanceWalletIcon>
                <span>
                  {wallet ? wallet.slice(0, 8) : "Please connect your wallet"}{" "}
                  {wallet && "..."}
                </span>
              </div> */}
            </div>
            <center className={classes.mid}>
              <img src={chest1} className={classes.left_chest}></img>
              <img src={chest2} className={classes.right_chest}></img>

              <img src={logo} className={classes.logo}></img>
              <div className={classes.txt_11}>
                Where everybody wins but you can win{" "}
                <span className={classes.bold}>BIG</span>
              </div>

              <div
                className={`${classes.txt_11} ${classes.txt_111} `}
                style={{ fontSize: "20px" }}
              >
                <span className={classes.bold2}>Play</span> the Rolling Raffle
                Now!
                <img src={Ticket} className={classes.ticket} />
              </div>
              {contractData &&
              contractData.gameStarted &&
              getcurrentTimestamp() > 1621389600 ? (
                <div className={classes.prizeGoal}>
                  <div className={classes.txtAmount}>
                    {cetoBalance && (cetoBalance / 1e6).toFixed(0)} CETO
                  </div>
                  <div className={classes.txtAmount1}>
                    ({tokens_to_crypto && (tokens_to_crypto / 1e6).toFixed(2)}{" "}
                    TRX)
                  </div>
                  <div className={classes.txt13}>Guaranteed Prize Goal</div>
                </div>
              ) : (
                <div className={classes.prizeGoal}>
                  <div className={classes.txt13}>
                    The next Big Tent event starts on
                  </div>
                  <div className={classes.txtAmount}>
                    {countdownTimer && (
                      <div className={classes.txtAmount}>
                        <span className="banner-txt-2">
                          {countdownTimer
                            ? countdownTimer.days < 10
                              ? String(countdownTimer.days).padStart(2, "0")
                              : countdownTimer.days
                            : "00"}{" "}
                          d{" "}
                          {countdownTimer
                            ? countdownTimer.hours < 10
                              ? String(countdownTimer.hours).padStart(2, "0")
                              : countdownTimer.hours
                            : "00"}{" "}
                          h{" "}
                        </span>

                        <span className="banner-txt-2">
                          {countdownTimer
                            ? countdownTimer.minutes < 10
                              ? String(countdownTimer.minutes).padStart(2, "0")
                              : countdownTimer.minutes
                            : "00"}{" "}
                          m{" "}
                          {countdownTimer
                            ? countdownTimer.seconds < 10
                              ? String(countdownTimer.seconds).padStart(2, "0")
                              : countdownTimer.seconds
                            : "00"}{" "}
                          s
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </center>
            <div className={classes.box__11}>
              <div className={classes.part1}>
                <img src={coins} className={classes.coins}></img>
                <div className={classes.right_}>
                  <div className={classes.txt111}>Jackpot</div>
                  <div className={classes.txt112}>
                    {cetoBalance && (cetoBalance / 1e6).toFixed(0)}{" "}
                    <span className={classes.small}>ceto</span>
                  </div>
                  <div className={classes.dropdownDiv} onClick={handleClick}>
                    <span className={classes.currency}>
                      {tokens_to_crypto && (tokens_to_crypto / 1e6).toFixed(2)}{" "}
                      TRX
                    </span>
                    <img src={dropdown} className={classes.dropdown} />
                  </div>
                  {/* <div className={classes.txt113}> */}

                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    // PaperProps={{
                    //   style: {
                    //     maxHeight: ITEM_HEIGHT * 5.15,
                    //     width: "120px",
                    //     paddingRight: "5px",
                    //   },
                    // }}
                  >
                    <div className={classes.menuItems}>
                      {[
                        "USD",

                        "AED",
                        "BHD",
                        "CAD",
                        "CHF",
                        "EUR",
                        "GBP",
                        "GIP",
                        "HKD",
                        "INR",
                        "JOD",
                        "JPY",
                        "KWD",
                        "OMR",
                        "PAB",
                      ].map(curr => {
                        return (
                          <StyledMenuItem key={curr}>
                            <ListItemText
                              primary={`${
                                tokens_to_crypto &&
                                (
                                  (tokens_to_crypto / 1e6) *
                                  (currencies && currencies[curr])
                                ).toFixed(2)
                              } ${curr}`}
                              className={classes.line8}
                            />
                          </StyledMenuItem>
                        );
                      })}
                    </div>
                  </StyledMenu>
                </div>
                {/* </div> */}
              </div>
              <div className={classes.line}></div>
              <div className={classes.part2}>
                {" "}
                <img src={timer2} className={classes.timer2}></img>
                <div className={classes.right_}>
                  <div className={classes.txt114}>
                    {(contractData && contractData.startDate) || timer
                      ? `Winners drawn ${timer ? "in" : "at"}`
                      : "Game hasn't started yet"}
                  </div>
                  {timer && (
                    <div className={classes.txt115}>
                      <span className="banner-txt-2">
                        {timer
                          ? timer.days < 10
                            ? String(timer.days).padStart(2, "0")
                            : timer.days
                          : "00"}{" "}
                        d{" "}
                        {timer
                          ? timer.hours < 10
                            ? String(timer.hours).padStart(2, "0")
                            : timer.hours
                          : "00"}{" "}
                        h <br></br>
                      </span>
                    </div>
                  )}
                  {timer && (
                    <div className={classes.txt115}>
                      <span className="banner-txt-2">
                        {timer
                          ? timer.minutes < 10
                            ? String(timer.minutes).padStart(2, "0")
                            : timer.minutes
                          : "00"}{" "}
                        m{" "}
                        {timer
                          ? timer.seconds < 10
                            ? String(timer.seconds).padStart(2, "0")
                            : timer.seconds
                          : "00"}{" "}
                        s
                      </span>
                      {/* <Tooltip
                        title={`Or when the start date is reached`}
                        color="black"
                      >
                        <IconButton className={classes.i}>
                          <img src={i}></img>
                        </IconButton>
                      </Tooltip> */}
                    </div>
                  )}
                  {!timer && contractData && contractData.startDate ? (
                    <div className={classes.txt115}>
                      {/* Sat March 20 - 8:00 PM */}
                      {contractData &&
                        new Date(
                          parseInt(
                            contractData.startDate
                              ? contractData.startDate
                              : 23400
                          ) * 1000
                        )
                          .toDateString()
                          .slice(0, 10)}{" "}
                    </div>
                  ) : null}
                  {!timer && contractData && contractData.startDate ? (
                    <div className={classes.txt115}>
                      <div className={classes.txt115}>
                        {contractData && getTimeString()}
                        {/* {contractData &&
                          new Date(parseInt(contractData.startDate * 1000))
                            .toLocaleString()
                            .split(", ")[1]
                            .slice(-2)} */}
                      </div>
                      <Tooltip
                        title={`Or 24 hours after GPG is reached`}
                        color="black"
                      >
                        <IconButton className={classes.i}>
                          <img src={i}></img>
                        </IconButton>
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={classes.line}></div>
              <div className={classes.part3}>
                <div className={classes.txt116}>
                  <span className={classes.orange}>
                    {contractData && contractData.participantsCount}
                  </span>{" "}
                  Tickets Sold
                </div>
                <div className={classes.bottom_div}>
                  <div className={classes.txt117}>
                    {tronPrice && tronPrice.toFixed(2)} TRX / TICKET
                    <Tooltip
                      title={`${
                        contractData && contractData.ticketPrice / 1e6
                      } CETO / Ticket`}
                      color="black"
                    >
                      <IconButton
                        className={classes.i}
                        style={{ marginTop: "-3px" }}
                      >
                        <img src={i}></img>
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.buy_btn}
                    onClick={() => checkConnection(1)}
                  >
                    BUY TICKET
                  </Button>
                </div>
              </div>
            </div>
            <div className={classes.bottom_div_}>
              <div className={classes.txt117}>
                {tronPrice && tronPrice.toFixed(2)} TRX / TICKET
                <Tooltip
                  title={`${
                    contractData && contractData.ticketPrice / 1e6
                  } CETO / Ticket`}
                  color="black"
                >
                  <IconButton
                    className={classes.i}
                    style={{ marginTop: "-3px" }}
                  >
                    <img src={i}></img>
                  </IconButton>
                </Tooltip>
              </div>
              <Button
                variant="contained"
                size="large"
                className={classes.buy_btn}
                onClick={() => checkConnection(1)}
              >
                BUY TICKET
              </Button>
            </div>

            {contractData && userTickets >= 1 && contractData.gameStarted && (
              <div className={classes.txt22}>
                You have{" "}
                <span className={classes.orange}>
                  {(contractData && contractData.participantsCount < 3) ||
                  (contractData &&
                    contractData.participantsCount - userTickets < 3) ||
                  (contractData &&
                    userTickets &&
                    contractData.participantsCount == userTickets)
                    ? "100"
                    : prob && prob.toFixed(2)}
                  %
                </span>{" "}
                chance of winning with
                <span className={classes.orange}> {userTickets}</span>{" "}
                {userTickets && userTickets > 1 ? "Tickets" : "Ticket"}
              </div>
            )}

            <div className={classes.prizes}>
              <div className={classes.prize}>
                <center className={classes.circle}>2nd</center>

                <div className={classes.amount}>
                  <img src={stem1}></img>
                  {contractData &&
                    (deductFee(contractData.prizes[1]) / 1e6).toFixed(2)}{" "}
                  <span className={classes.sub}>CETO</span>
                  <img src={stem2}></img>
                </div>
                <img src={cup} className={classes.cup}></img>
              </div>

              <div className={`${classes.prize} ${classes.first}`}>
                <center className={classes.circle}>1st</center>

                <div className={classes.amount}>
                  <img src={stem1}></img>
                  {contractData &&
                    (deductFee(contractData.prizes[0]) / 1e6).toFixed(2)}{" "}
                  <span className={classes.sub}>CETO</span>
                  <img src={stem2}></img>
                </div>
                <img src={cup} className={classes.cup}></img>
              </div>
              <div className={classes.prize}>
                <center className={classes.circle}>3rd</center>

                <div className={classes.amount}>
                  <img src={stem1}></img>
                  {contractData &&
                    (deductFee(contractData.prizes[2]) / 1e6).toFixed(2)}{" "}
                  <span className={classes.sub}>CETO</span>
                  <img src={stem2}></img>
                </div>
                <img src={cup} className={classes.cup}></img>
              </div>
            </div>
            {rebate && rebate > 0 ? (
              <div className={classes.rebate}>
                <div className={classes.r_txt}>
                  {/* <IconButton className={classes.i}>
                    <img src={i}></img>
                  </IconButton> */}
                  To claim your Current Rebate{" "}
                  <span className={classes.r_txt2}>
                    ({rebate && (rebate / 1e6).toFixed(3)} CETO){" "}
                  </span>
                  <br className={classes.break} />
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.r_btn}
                    onClick={() => checkConnection(2)}
                  >
                    click here
                  </Button>
                </div>
                <div>
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.mobileButton}
                    onClick={() => checkConnection(2)}
                  >
                    click here
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        {connectModal && (
          <ConnectionModal
            openModal={connectModal}
            connection_issue={connection_issue}
            setConnectModal={setConnectModal}
            blockchain="trx"
            node={node}
          ></ConnectionModal>
        )}
      </div>
    </>
  );
}
